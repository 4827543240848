import React, {useEffect, useState} from 'react';
import {Input} from "antd";
import {ICriteriaForUse} from "../../utils/models/response/IStudentBallRes";

interface InterfaceLastCard {
  ballArr: ICriteriaForUse[]
}

const LastCard: React.FC<InterfaceLastCard> = ({ballArr}) => {
  const [sum, setSum] = useState(0);

  useEffect(() => {
    let newSum = 0
    ballArr.forEach(item => {
      newSum += item.ball
    })
    setSum(newSum)
  }, [ballArr]);

  return (
    <div className={'last_card'}>
      <div className={'last_card_title'}>
        Jami
      </div>
      <Input
        value={sum}
        className={'last_card_input'} />
    </div>
  );
}

export default LastCard;