import React from 'react'
import HeaderCommission from './HeaderCommission'
import EvaluationCont from './EvaluationCont'
import { useOneStudentBall } from '../../queries/queries'
import { useParams } from 'react-router-dom'
import { Spin } from 'antd'

const Commission = () => {
  const params = useParams<{ id: string }>()

  const { isLoading, isFetching, data } = useOneStudentBall(params.id)

  if (isLoading)
    return (
      <div className={'spinner'}>
        <Spin size={'large'} />
      </div>
    )

  return (
    <div>
      <HeaderCommission
        file={data?.file || ''}
        id={data?.id || 0}
        name={data?.name || ''}
        isFetching={isFetching}
        file_name={data?.file_name || ''}
        ppt={data?.ppt || ''}
        ppt_name={data?.ppt_name || ''}
      />
      <EvaluationCont
        status={data?.status || 'doing'}
        id={data?.id || 0}
        criteria={data?.criteria || []}
      />
    </div>
  )
}

export default Commission
