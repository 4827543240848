export const apis = {
  LOGIN: '/login',
  LOGIN_COMMISSION: '/logincommission',
  RESET_PASSWORD: '/resetpassword',
  CREATE_USERS: '/register',
  COMMISSION_LIST: "/user/commission",
  LEADER_LIST: "/user/director",
  FACULTY: '/direction',
  USER: '/user',
  GROUP: '/group',
  GROUP_DATE: '/groupdate',
  FACULTY_ALL: '/directionselect',
  STUDENT: '/student',
  IMPORT_STUDENTS: '/groupimport',
  COMMISSION_ATTACH: '/selectcommission',
  ATTACH_GROUP: "/groupattach",
  DOWNLOAD: '/download',
  DOWNLOAD_GROUP: '/groupdownload',
  MY_GROUP: '/mygroup',
  STUDENT_BALL: '/studentball',
  STUDENT_INFO: '/studentinfo',
  EDIT_STUDENT_INFO: '/editinfo',
  DELETE_STUDENT: '/delete/student',
  DELETE_COMMISSION: '/delete/commission',
  DELETE_FACULTY: '/delete/direction'
}
