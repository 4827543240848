import NotSelect from "../../pages/commission/NotSelect";
import Commission from "../../pages/commission/Commission";

export const commissionPaths = {
  home: '/:id',
  notSelect: '/'
}

export const commissionRoutes = [
  {
    path: commissionPaths.notSelect,
    component: NotSelect,
    exact: true
  },
  {
    path: commissionPaths.home,
    component: Commission,
    exact: true,
  }
]