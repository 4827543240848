import React, {useState} from "react";
import {useQueryClient} from 'react-query';
import HeaderTable from "./HeaderTable";
import OneFaculty from "./OneFaculty";
import CreateAndEditFacultiesModal from "./CreateAndEditFacultiesModal";
import EditButton from "../EditButton";
import PaginationComp from "../../../components/common/PaginationComp";
import {useSettingFaculties} from "../../../queries/queries";
import {queryNames} from "../../../utils/constants/queryNames";
import {Spin} from "antd";
import {IFaculty} from "../../../utils/models/IFaculty";
import {useAppSelector} from "../../../utils/helpers/customizedHooks";

const Faculties = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editData, setEditData] = useState<IFaculty | null>(null);

  const qc = useQueryClient()

  const {isFetching, isLoading, data} = useSettingFaculties(currentPage)
  const role = useAppSelector((state) => state.user.user?.role)

  const openEditModalFunc: (data?: any) => void = () => {
    setOpenEditModal(true)
  }

  const closeEditModalFunc = () => {
    qc.invalidateQueries([queryNames.FACULTIES_LIST, currentPage])
    setEditData(null)
    setOpenEditModal(false)
  }

  const editFunc = (editData: IFaculty) => {
    setEditData(editData)
    setOpenEditModal(true)
  }

  return (
    <div>
      {role !== "director" &&
      <EditButton openEditModal={openEditModalFunc} title={"Yo'nalish qo’shish"}/>
      }
      <HeaderTable isFetching={isFetching}/>
      {isLoading ? <Spin className={'spin'}/> :
        data?.data.map((item, index) => (
          <OneFaculty
            editData={editFunc}
            data={item}
            currentPage={currentPage}
            index={index}
            key={index}/>
        ))}
      <div className={'pagination'}>
        <PaginationComp
          paginationProps={{
            total: data?.total || 0,
            current: currentPage,
            onChange: (val) => {
              setCurrentPage(val)
            }
          }}/>
      </div>
      <CreateAndEditFacultiesModal data={editData} close={closeEditModalFunc} open={openEditModal}/>
    </div>
  )
}

export default Faculties