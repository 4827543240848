import React from 'react'

export const Polygonn = () => {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 7L0.75 13.0622L0.750001 0.937822L9 7Z" fill="#31D59A" />
    </svg>
  )
}
