import RootRouter from './routing/RootRouter'

function App() {
  return (
    <div>
      <RootRouter />
    </div>
  )
}

export default App
