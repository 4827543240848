import React from "react";

const GarbageMini = () => {
  return (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.99984 0.833374C4.25094 0.833374 2.83317 2.25114 2.83317 4.00004V4.16671H0.666504C0.390362 4.16671 0.166504 4.39056 0.166504 4.66671C0.166504 4.94285 0.390362 5.16671 0.666504 5.16671L11.3332 5.16671C11.6093 5.16671 11.8332 4.94285 11.8332 4.66671C11.8332 4.39056 11.6093 4.16671 11.3332 4.16671H9.1665V4.00004C9.1665 2.25114 7.74874 0.833374 5.99984 0.833374Z" fill="#649BD3"/>
      <path d="M10.3446 8.5197L10.1697 9.77639C9.97967 11.1422 9.88463 11.8251 9.68312 12.3736C9.30628 13.3992 8.65489 14.1632 7.87741 14.4913C7.46164 14.6667 6.97437 14.6667 5.99984 14.6667C5.02531 14.6667 4.53803 14.6667 4.12227 14.4913C3.34479 14.1632 2.69339 13.3992 2.31656 12.3736C2.11504 11.8251 2.02001 11.1422 1.82993 9.7764L1.65504 8.51971C1.60121 8.13292 1.55205 7.77967 1.50831 7.45533C1.41845 6.78899 1.37352 6.45582 1.57275 6.22793C1.77197 6.00004 2.11438 6.00004 2.79919 6.00004L9.20048 6.00004C9.8853 6.00004 10.2277 6.00004 10.4269 6.22793C10.6261 6.45582 10.5812 6.78899 10.4914 7.45533C10.4476 7.77958 10.3984 8.13305 10.3446 8.5197Z" fill="#649BD3"/>
    </svg>
  )
}

export default GarbageMini