import Home from "../../pages/main/home/Home";
import NotSelectDirection from "../../pages/main/home/NotSelectDirection";

export const mainPaths = {
  home: '/:date/:id',
  notSelect: '/:date'
}

export const mainRoutes = [
  {
    path: mainPaths.home,
    component: Home,
    exact: true,
  },
  {
    path: mainPaths.notSelect,
    component: NotSelectDirection,
    exact: true
  }
]
