import React from "react";

const Pencil = () => {
  return (
    <svg className={'pencil'} width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2041 1.5755L11.2941 1.6275C12.0723 2.07671 12.7211 2.4513 13.1962 2.81795C13.6977 3.20494 14.0957 3.64914 14.2625 4.27132C14.4292 4.8935 14.3066 5.4772 14.0658 6.06309C13.8986 6.46976 13.6529 6.92674 13.3567 7.44801L12.7214 7.0704L12.7132 7.0656L5.78791 3.06728L5.14026 2.68557C5.44035 2.17448 5.71074 1.73795 5.97693 1.393C6.36392 0.891518 6.80812 0.49349 7.4303 0.326777C8.05249 0.160063 8.63618 0.282668 9.22207 0.523468C9.77715 0.751604 10.426 1.12624 11.2041 1.5755Z" fill="#3F4B57"/>
      <path d="M4.38884 3.98384L0.910867 10.0077C0.61308 10.5225 0.377621 10.9296 0.290385 11.3926C0.203149 11.8556 0.274336 12.3204 0.364367 12.9083L0.388623 13.0672C0.554809 14.1586 0.69179 15.0581 0.89839 15.7403C1.11446 16.4537 1.44662 17.0712 2.09989 17.4484C2.75316 17.8256 3.45402 17.8045 4.17991 17.6349C4.87398 17.4727 5.72151 17.1416 6.74974 16.7398L6.89949 16.6814C7.45361 16.4654 7.89176 16.2946 8.24912 15.9876C8.60648 15.6805 8.84127 15.2731 9.13821 14.7578L12.6079 8.74792L11.9591 8.36226L5.02918 4.36127L4.38884 3.98384Z" fill="#3F4B57"/>
    </svg>
  )
}

export default Pencil