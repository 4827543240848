import React, {useState} from "react";
import Download from "../../../assets/svg/Download";
import PlusWhite from "../../../assets/svg/PlusWhite";
import PlusBlue from "../../../assets/svg/PlusBlue";
import {LoadingOutlined} from "@ant-design/icons";
import CreateAndEditStudentModal from "./CreateAndEditStudentModal";
import AttachModal from "./AttachModal";
import {
  useDeleteGroupMutation,
  useDownloadStudentListMutation,
  useImportStudentsMutation
} from "../../../queries/mutations";
import {Button, Spin} from "antd";
import Import from "../../../assets/svg/Import";
import Garbage from "../../../assets/svg/Garbage";
import {useHistory} from "react-router";

interface InterfaceHomeHeader {
  name: string
  id: number
  count: number
  isFetching: boolean,
  date: string
}

const HomeHeader: React.FC<InterfaceHomeHeader> = ({count, name, isFetching, id,date}) => {
  const {push} = useHistory()

  const [openModal, setOpenModal] = useState(false);
  const [attachModalOpen, setAttachModalOpen] = useState(false);


  const download = useDownloadStudentListMutation(`${name}.pdf`)
  const deleteGroupMutation = useDeleteGroupMutation(push,date)
  const importStudent = useImportStudentsMutation(id,date)

  const closeModalFunc = () => {
    setOpenModal(false)
  }

  const closeAttachModal = () => {
    setAttachModalOpen(false)
  }

  const deleteGroupFunc = () => {
    deleteGroupMutation.mutate(id)
  }

  const downloadFunc = () => {
    download.mutate(id)
  }

  const importFunc = (file: File) => {
    const formData = new FormData()
    formData.append('id', id as any)
    formData.append('file', file)
    importStudent.mutate(formData)
  }

  return (
    <>
      <div className={'header'}>
        <div className={'header_left'}>
          <span className={'header_left_title'}>
            {name}
            {isFetching && <LoadingOutlined/>}
          </span>
          <span className={'header_left_count'}>
          {count !== 0
            ?
            `Jami tinglovchilar: ${count} ta`
            :
            <Button
              onClick={deleteGroupFunc}
              loading={deleteGroupMutation.isLoading}
              className={'delete_group'}
              type="primary"
              shape="round"
              icon={<Garbage/>}
              size={'middle'}/>}
          </span>
        </div>
        <div className={'header_right'}>
          <div>
            <label
              htmlFor={'fileImport'}
              className={'header_right_download'}>
              {importStudent.isLoading ?
                <Spin/>
                :
                <>
                  <Import/>
                  <span>
                  XLS jadvalni yuklash
                </span>
                </>
              }
            </label>
            <input
              style={{
                display: 'none'
              }}
              onChange={(e) => {
                if (e.target.files && e.target.files.length === 1) {
                  importFunc(e.target.files[0])
                }
              }}
              id={'fileImport'}
              accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
              type={'file'}/>
          </div>
          {count !== 0 &&
          <div
            onClick={downloadFunc}
            className={'header_right_download'}>
            {download.isLoading ?
              <Spin/>
              :
              <>
                <Download/>
                <span>
                  PDF yuklab olish
                </span>
              </>
            }
          </div>
          }
          <div
            onClick={() => setAttachModalOpen(true)}
            className={'header_right_add_commission'}>
            <PlusBlue/>
            <span>
            Komissiya a’zolari
          </span>
          </div>
          <div
            onClick={() => setOpenModal(true)}
            className={'header_right_add_student'}>
            <PlusWhite/>
            <span>
            Tinglovchi qo’shish
          </span>
          </div>
        </div>
      </div>
      <CreateAndEditStudentModal
        date={date}
        open={openModal}
        closeFunc={closeModalFunc}
        id={id}
        title={'Yangi tinglovchi qo‘shish'}/>
      <AttachModal open={attachModalOpen} close={closeAttachModal} id={parseInt(id as any)}/>
    </>
  )
}

export default HomeHeader