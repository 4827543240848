import React from "react";

const SaveButtonIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.69672 11.8369C9.19462 12 8.57419 12 7.33333 12H4.66667C3.42581 12 2.80539 12 2.30328 11.8369C1.28848 11.5071 0.492871 10.7115 0.163145 9.69672C0 9.19462 0 7.24085 0 6V1.79592C0 1.18664 0 0.882007 0.0960302 0.64699C0.191345 0.413724 0.351039 0.225513 0.548961 0.113178C0.74837 0 1.00685 0 1.52381 0C2.81621 0 3.46241 0 3.96093 0.282946C4.45574 0.563783 4.85497 1.03431 5.09326 1.61748L5.09954 1.63283C5.3257 2.18612 5.8024 2.66561 6.40011 2.66883C7.69614 2.67581 9.31348 2.70529 9.69672 2.82981C10.7115 3.15954 11.5071 3.95515 11.8369 4.96994C12 5.47205 12 6.09248 12 7.33333C12 8.57419 12 9.19462 11.8369 9.69672C11.5071 10.7115 10.7115 11.5071 9.69672 11.8369ZM6.5 5.33333C6.5 5.05719 6.27614 4.83333 6 4.83333C5.72386 4.83333 5.5 5.05719 5.5 5.33333V6.83333H4C3.72386 6.83333 3.5 7.05719 3.5 7.33333C3.5 7.60948 3.72386 7.83333 4 7.83333H5.5V9.33333C5.5 9.60948 5.72386 9.83333 6 9.83333C6.27614 9.83333 6.5 9.60948 6.5 9.33333V7.83333H8C8.27614 7.83333 8.5 7.60948 8.5 7.33333C8.5 7.05719 8.27614 6.83333 8 6.83333H6.5V5.33333Z" fill="white"/>
    </svg>
  )
}

export default SaveButtonIcon