import React from "react";

const User = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.00004 1.33334C6.25114 1.33334 4.83337 2.75111 4.83337 4.50001C4.83337 6.24891 6.25114 7.66668 8.00004 7.66668C9.74894 7.66668 11.1667 6.24891 11.1667 4.50001C11.1667 2.75111 9.74894 1.33334 8.00004 1.33334Z" fill="#3F4B57"/>
      <path d="M6.00004 8.66668C4.25114 8.66668 2.83337 10.0844 2.83337 11.8333C2.83337 13.5822 4.25114 15 6.00004 15H10C11.7489 15 13.1667 13.5822 13.1667 11.8333C13.1667 10.0844 11.7489 8.66668 10 8.66668H6.00004Z" fill="#3F4B57"/>
    </svg>
  )
}

export default User