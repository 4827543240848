import React, {useState} from "react";
import EditButton from "../EditButton";
import CreateAndEditUser from "../CreateAndEditUser";
import OneLeader from "./OneLeader";
import LeaderHeader from './LeaderHeader'
import PaginationComp from "../../../components/common/PaginationComp";
import {User} from "../../../utils/models/User";
import {useSettingLeader} from "../../../queries/queries";
import {queryNames} from "../../../utils/constants/queryNames";
import {useQueryClient} from "react-query";
import {Spin} from "antd";
import {useAppSelector} from "../../../utils/helpers/customizedHooks";

const Leader = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editData, setEditData] = useState<User | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const qc = useQueryClient()

  const {isFetching, isLoading, data} = useSettingLeader(currentPage)
  const role = useAppSelector((state) => state.user.user?.role)

  const openEditModalFunc: (data?: any) => void = () => {
    setOpenEditModal(true)
  }

  const closeEditModalFunc = () => {
    setOpenEditModal(false)
    setEditData(null)
    qc.invalidateQueries([queryNames.LEADER_LIST, currentPage])
  }

  const editFunc = (editData: User) => {
    setEditData(editData)
    setOpenEditModal(true)
  }

  return (
    <div>
      {role !== 'director' &&
      <EditButton openEditModal={openEditModalFunc} title={'Boshqaruv a\'zolari yaratish'}/>
      }
      <LeaderHeader isFetching={isFetching}/>
      {isLoading ?
        <Spin className={'spin'}/> :
        data?.data?.map((item, key) => (
          <OneLeader data={item} editData={editFunc} key={key}/>
        ))}
      <div className={'pagination'}>
        <PaginationComp paginationProps={{
          total: data?.total || 0,
          current: currentPage,
          onChange: (val) => {
            setCurrentPage(val)
          }
        }}/>
      </div>
      <CreateAndEditUser
        data={editData}
        title={"Komissiya raisi qo’shish"}
        close={closeEditModalFunc}
        open={openEditModal}
        role={'director'}/>
    </div>
  )
}

export default Leader