import React, {useEffect} from "react";
import {Button, Form, Input, message} from "antd";
import PhoneInput from "react-phone-input-2";
import {useHistory} from "react-router";
import {useAppDispatch} from "../../utils/helpers/customizedHooks";
import {useChangePasswordMutation, useLoginMutation} from "../../queries/mutations";
import {LocalStorage} from "../../services/LocalStorage";
import {TOKEN_KEY, USER_KEY} from "../../utils/constants/localStorageKeys";
import {User} from "../../utils/models/User";
import {rootPaths} from "../../utils/routes/rootPaths";

const LoginAdmin = () => {
  const [form] = Form.useForm()
  const { push } = useHistory()
  const dispatch = useAppDispatch()

  const login = useLoginMutation(dispatch, push)
  const resetPassword = useChangePasswordMutation()

  const onFinish = (values: any) => {
    const newPhone = getPhoneNumberFunc(values.phone)
    login.mutate({
      ...values,
      phone: newPhone,
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const resetFunc = () => {
    const phone = form.getFieldValue('phone')

    if (phone && phone.length === 12) {
      const newPhone = getPhoneNumberFunc(phone)
      resetPassword.mutate({ phone: newPhone })
    } else {
      message.error('Telefon raqam xato kiritilgan!')
    }
  }

  const getPhoneNumberFunc = (number: string) => {
    let newPhone = ''
    for (let i = 0; i < 12; i++) {
      if (i === 0) {
        newPhone += `+${number[i]}`
      } else if (i === 3 || i === 5 || i === 8 || i === 10) {
        newPhone += ` ${number[i]}`
      } else {
        newPhone += `${number[i]}`
      }
    }
    return newPhone
  }

  useEffect(() => {
    const token = LocalStorage.get(TOKEN_KEY)
    const user: User = LocalStorage.get(USER_KEY) as User
    if (token) {
      if (user.role === 'commission') {
        push(rootPaths.commission)
      } else {
        push(rootPaths.main+'/new')
      }
    }
    // eslint-disable-next-line
  }, [])
  return (
    <div className="login">
      <div className="loginRegister">
        <h1>KIRISH</h1>
        <Form
          form={form}
          className="loginForm"
          layout={'vertical'}
          requiredMark={false}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Telefon raqam"
            name="phone"
            rules={[
              {
                required: true,
                message: 'Telefon raqam kiritilmagan!',
                len: 12,
              },
            ]}
          >
            <PhoneInput
              inputStyle={{
                width: '421px',
                height: '45px',
              }}
              disableDropdown={true}
              enableAreaCodes={true}
              enableAreaCodeStretch
              placeholder="+998 XX XXX XX XX"
              copyNumbersOnly={false}
              country={'uz'}
              onlyCountries={['uz']}
              masks={{ uz: ' ..  ... .. ..' }}
              onChange={(phone) => {
                console.log(phone)
              }}
            />
          </Form.Item>

          <Form.Item
            label="Parol"
            name="password"
            rules={[
              {
                required: true,
                message: 'Parol xato kiritilgan!',
              },
            ]}
          >
            <Input.Password type={'number'} placeholder="*****************" />
          </Form.Item>
          <Button
            loading={resetPassword.isLoading}
            onClick={resetFunc}
            type={'link'}
            className="changePassword"
          >
            Parolni unutdingizmi?
          </Button>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button loading={login.isLoading} type="primary" htmlType="submit">
              Kirish
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default LoginAdmin