import {useMutation, useQueryClient} from "react-query";
import $api from "../services/ApiService";
import {apis} from "../utils/constants/api";
import {ILoginResponse} from "../utils/models/response/ILoginResponse";
import {AuthService} from "../services/AuthService";
import {BossType, User, UserUpdate} from "../utils/models/User";
import {FormInstance, message} from "antd";
import {queryNames} from "../utils/constants/queryNames";
import {rootPaths} from "../utils/routes/rootPaths";
import {ICriteriaForUse} from "../utils/models/response/IStudentBallRes";

export function useLoginMutation(dispatch: any, push: any) {
  return useMutation(
    async (data: any) => {
      const res = await $api.post<ILoginResponse>(apis.LOGIN, data)
      return res.data
    }, {
      onSuccess: (data) => {
        AuthService.login(data, dispatch, push)
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.messege)
      }
    }
  )
}

export function useLoginCommissionMutation(dispatch: any, push: any) {
  return useMutation(
    async (password: string) => {
      const res = await $api.post<ILoginResponse>(apis.LOGIN_COMMISSION, {
        password
      })
      return res.data
    }, {
      onSuccess: (data) => {
        AuthService.login(data, dispatch, push)
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.messege)
      }
    }
  )
}

export function useChangePasswordMutation(openCodeSection?: () => void) {
  return useMutation(
    async (data: { phone: string }) => {
      const res = await $api.post(apis.RESET_PASSWORD, data)
      return res.data
    }, {
      onSuccess: () => {
        message.success("Parol o'zgartirildi va xabar jo'natildi!")
        if (openCodeSection) openCodeSection()
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useCreateUserMutation(close: () => void, form: FormInstance<UserUpdate>) {
  return useMutation(
    async (user: User) => {
      console.log(user)
      const res = await $api.post(apis.CREATE_USERS, user)
      return res.data
    }, {
      onSuccess: () => {
        close()
        form.resetFields()
        message.success('Foydalanuvchi yaratildi')
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useUpdateUserMutation(close: () => void, form: FormInstance<UserUpdate>) {
  return useMutation(
    async (data: {
      name: string,
      phone: string,
      id: number
      type?: BossType
    }) => {
      const res = await $api.post(`${apis.CREATE_USERS}/${data.id}`, {
        name: data.name,
        phone: data.phone,
        type: data?.type
      })
      return res.data
    }, {
      onSuccess: () => {
        form.resetFields()
        close()
        message.success("Foydalanuvchi ma'lumotlari yangilandi")
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useChangeStatusUserMutation(page: number) {
  const qc = useQueryClient()
  return useMutation(
    async (data: { id: number, status: string }) => {
      const res = await $api.put(apis.USER, {
        id: data.id,
        status: data.status
      })
      return res.data
    }, {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.COMMISSION_LIST, page])
        message.success("Foydalanuvchi statusi o'zgardi")
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useCreateFacultyMutation() {
  return useMutation(
    async (name: string) => {
      const res = await $api.post(apis.FACULTY, {
        name
      })
      return res.data
    }, {
      onSuccess: () => {
        message.success("Yo'nalish yaratildi")
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useUpdateFacultyMutation() {
  return useMutation(
    async (data: { id: number, name: string }) => {
      const res = await $api.post(`${apis.FACULTY}/${data.id}`, {
        name: data.name
      })
      return res.data
    }, {
      onSuccess: () => {
        message.success("Yo'nalish ma'lumotlari yangilandi")
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useCreateDirectionMutation(push: any, closeFunc: () => void) {
  const qc = useQueryClient()
  return useMutation(
    async (data: { direction_id: number, date: string, date_end: string }) => {
      const res = await $api.post(apis.GROUP, data)
      return res.data
    }, {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.GROUP_LIST])
        closeFunc()
        message.success("Yo'nalish yaratildi")
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useCreateStudentMutation(closeFunc: () => void, id: number,date: string) {
  const qc = useQueryClient()
  return useMutation(
    async (data: FormData) => {
      const res = await $api.post(apis.STUDENT, data)
      return res.data
    }, {
      onSuccess: () => {
        message.success("Tinglovchi yaratildi")
        qc.invalidateQueries([queryNames.GROUP_LIST_STUDENT, parseInt(id as any)])
        qc.invalidateQueries([queryNames.GROUP_LIST,date])
        closeFunc()
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useImportStudentsMutation(id: number,date: string) {
  const qc = useQueryClient()
  return useMutation(
    async (data: FormData) => {
      const res = await $api.post(apis.IMPORT_STUDENTS, data)
      return res.data
    }, {
      onSuccess: () => {
        message.success("Tinglovchilar yaratildi")
        qc.invalidateQueries([queryNames.GROUP_LIST_STUDENT, parseInt(id as any)])
        qc.invalidateQueries([queryNames.GROUP_LIST,date])
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useUpdateStudentMutation(closeFunc: () => void, id: number,date: string) {
  const qc = useQueryClient()
  return useMutation(
    async (data: {
      form: FormData
      id: number
    }) => {
      const res = await $api.post(`${apis.STUDENT}/${data.id}`, data.form)
      return res.data
    }, {
      onSuccess: () => {
        message.success("Tinglovchi ma'lumotlari yangilandi!")
        qc.invalidateQueries([queryNames.GROUP_LIST_STUDENT, parseInt(id as any)])
        qc.invalidateQueries([queryNames.GROUP_LIST,date])
        closeFunc()
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useAttachMutation(id: number) {
  const qc = useQueryClient()
  return useMutation(
    async (data: {
      user_id?: number,
      group_id: number
    }) => {
      let body: {
        group_id: number,
        user_id?: number
      } = {
        group_id: data?.group_id
      }
      if (data?.user_id)
        body.user_id = data?.user_id
      const res = await $api.post(apis.ATTACH_GROUP, body)
      return res.data
    }, {
      onSuccess: (data) => {
        message.success(data?.message)
        qc.invalidateQueries([queryNames.COMMISSION_LIST_ATTACH, id])
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useDownloadStudentFileMutation(name: string) {
  return useMutation(
    async (data: {
      id: number
      type: 'ppt' | 'doc' | 'director'
    }) => {
      const res = await $api({
        method: 'get',
        url: `${apis.DOWNLOAD}/${data.id}/${data.type}`,
        responseType: 'blob',
      })
      return res?.data
    },
    {
      onSuccess: (data) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      onError: (err: any) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

export function useDownloadStudentListMutation(name: string) {
  return useMutation(
    async (id: number) => {
      const res = await $api({
        method: 'get',
        url: `${apis.DOWNLOAD_GROUP}/${id}`,
        responseType: 'blob',
      })
      return res?.data
    },
    {
      onSuccess: (data) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      onError: (err: any) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

export function useDeleteGroupMutation(push: any,date: string) {
  const qc = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await $api({
        method: 'delete',
        url: `${apis.GROUP}/${id}`,
      })
      return res?.data
    },
    {
      onSuccess: () => {
        message.success("Guruh o'chirildi!")
        push(rootPaths.main)
        qc.invalidateQueries([queryNames.GROUP_LIST,date])
      },
      onError: (err: any) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

export function useEvaluationMutation(id: number) {
  const qc = useQueryClient()
  return useMutation(
    async (data: {
      student_id: number
      criteria_ids: ICriteriaForUse[]
    }) => {
      const res = await $api.post(apis.STUDENT_BALL, data)
      return res.data
    }, {
      onSuccess: () => {
        message.success("Baho qo'yildi!")
        qc.invalidateQueries([queryNames.ONE_STUDENT_BALL, `${id}`])
        qc.invalidateQueries([queryNames.COMMISSION_NAVBAR])
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useEvaluationDirectorMutation(id: number) {
  const qc = useQueryClient()
  return useMutation(
    async (data: {
      id: number
      ball: number
    }[]) => {
      const res = await $api.post(apis.EDIT_STUDENT_INFO, {
        ball_ids: data
      })
      return res.data
    }, {
      onSuccess: () => {
        message.success("Baho o'zgartirildi!")
        qc.invalidateQueries([queryNames.ONE_STUDENT_INFO, id])
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useDownloadStudentChangeFileMutation(name: string) {
  return useMutation(
    async (id: number) => {
      const res = await $api({
        method: 'get',
        url: `${apis.DOWNLOAD}/${id}/file`,
        responseType: 'blob',
      })
      return res?.data
    },
    {
      onSuccess: (data) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      onError: (err: any) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

export function useSaveStudentChangeFileMutation(id: number) {
  const qc = useQueryClient()
  return useMutation(
    async (data: FormData) => {
      const res = await $api.post(apis.EDIT_STUDENT_INFO, data)
      return res.data
    }, {
      onSuccess: () => {
        message.success("File yuklandi!")
        qc.invalidateQueries([queryNames.ONE_STUDENT_INFO, id])
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useDeleteStudentMutation(id: number) {
  const qc = useQueryClient()
  return useMutation(
    async (student_id: number) => {
      const res = await $api.delete(`${apis.DELETE_STUDENT}/${student_id}`)
      return res.data
    }, {
      onSuccess: (data) => {
        message.success(data?.message)
        qc.invalidateQueries([queryNames.GROUP_LIST_STUDENT, parseInt(id as any)])
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useDeleteCommissionMutation(page: number) {
  const qc = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.DELETE_COMMISSION}/${id}`)
      return res.data
    }, {
      onSuccess: (data) => {
        message.success(data?.message)
        qc.invalidateQueries([queryNames.COMMISSION_LIST, page])
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}

export function useDeleteFacultyMutation(page: number) {
  const qc = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.DELETE_FACULTY}/${id}`)
      return res.data
    }, {
      onSuccess: (data) => {
        message.success(data?.message)
        qc.invalidateQueries([queryNames.FACULTIES_LIST, page])
      },
      onError: (e: any) => {
        message.error(e?.response?.data?.message)
      }
    }
  )
}