import React from "react";

const Garbage = () => {
  return (
    <svg className={'garbage'} width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 0.25C6.37665 0.25 4.25 2.37665 4.25 5V5.25L1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L17 6.75C17.4142 6.75 17.75 6.41421 17.75 6C17.75 5.58579 17.4142 5.25 17 5.25L13.75 5.25V5C13.75 2.37665 11.6234 0.25 9 0.25Z" fill="#3F4B57"/>
      <path d="M15.5172 11.7795L15.2549 13.6645C14.9697 15.7132 14.8272 16.7376 14.5249 17.5603C13.9597 19.0988 12.9826 20.2447 11.8164 20.7368C11.1927 21 10.4618 21 9 21C7.5382 21 6.80729 21 6.18365 20.7368C5.01742 20.2447 4.04033 19.0988 3.47508 17.5603C3.17281 16.7376 3.03025 15.7133 2.74514 13.6645L2.4828 11.7795C2.40206 11.1993 2.32832 10.6695 2.26271 10.1829C2.12792 9.18343 2.06053 8.68367 2.35936 8.34184C2.6582 8 3.17181 8 4.19903 8L13.801 8C14.8282 8 15.3418 8 15.6406 8.34184C15.9395 8.68367 15.8721 9.18343 15.7373 10.1829C15.6717 10.6693 15.5979 11.1995 15.5172 11.7795Z" fill="#3F4B57"/>
    </svg>
  )
}

export default Garbage