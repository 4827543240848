import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../utils/models/User'
import {LocalStorage} from "../../services/LocalStorage";
import {USER_KEY} from "../../utils/constants/localStorageKeys";

interface UserState {
  user: User | null
}

const user: User| null = LocalStorage.get(USER_KEY)

const initialState: UserState = {
  user: user,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      console.log(action.payload)
      state.user = action.payload
    }
  },
})

export const userActions = userSlice.actions
export default userSlice.reducer
