import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { rootRoutes } from '../utils/routes/rootRoutes'

interface rootRouterProps {}
//
const RootRouter: React.FC<rootRouterProps> = () => {
  return (
    <BrowserRouter>
      <div className='Container'>
        <div className='Layout'>
          <Switch>
            {rootRoutes.map((route) => (
              <Route
                path={route.path}
                exact={route.exact}
                key={route.path}
                component={route.component}
              />
            ))}
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  )
}

export default RootRouter
