import React from "react";

const Eye = () => {
  return (
    <svg className={'eye'} width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.4569 4.79694C15.4349 4.74756 14.9057 3.57343 13.7286 2.39624C12.1608 0.828308 10.1799 -0.000488281 7.99998 -0.000488281C5.82011 -0.000488281 3.83916 0.828308 2.27141 2.39624C1.09428 3.57343 0.565048 4.74756 0.543075 4.79694C0.514674 4.86087 0.5 4.93004 0.5 5C0.5 5.06996 0.514674 5.13913 0.543075 5.20306C0.565048 5.2525 1.09435 6.42633 2.27147 7.60333C3.83922 9.1709 5.82011 9.99951 7.99998 9.99951C10.1799 9.99951 12.1608 9.1709 13.7285 7.60334C14.9056 6.42634 15.4349 5.2525 15.4569 5.20307C15.4853 5.13914 15.5 5.06996 15.5 5C15.5 4.93005 15.4853 4.86087 15.4569 4.79694ZM7.99998 2.75006C8.44499 2.75006 8.88001 2.88202 9.25002 3.12925C9.62003 3.37649 9.90842 3.72789 10.0787 4.13902C10.249 4.55016 10.2936 5.00256 10.2068 5.43901C10.1199 5.87547 9.90564 6.27638 9.59097 6.59105C9.27631 6.90572 8.8754 7.12001 8.43894 7.20683C8.00248 7.29364 7.55008 7.24909 7.13895 7.07879C6.72781 6.90849 6.37641 6.6201 6.12918 6.25009C5.88194 5.88008 5.74998 5.44507 5.74998 5.00006C5.75066 4.40353 5.98793 3.83162 6.40974 3.40981C6.83155 2.988 7.40345 2.75073 7.99998 2.75006Z" fill="#3F4B57"/>
    </svg>
  )
}

export default Eye