import React, {useRef} from "react";
import {Button, DatePicker, Form, Modal, Select} from "antd";
import CloseModal from "../../../assets/svg/CloseModal";
import SaveButtonIcon from "../../../assets/svg/SaveButtonIcon";
import {useFacultyAll} from "../../../queries/queries";
import {useCreateDirectionMutation} from "../../../queries/mutations";
import {useHistory} from "react-router";

const {Option} = Select
const {RangePicker} = DatePicker;

interface CreateNewDirectionInterface {
  open: boolean
  close: () => void
}

interface FormInterface {
  date: string
  direction_id: number
}

const CreateNewDirection: React.FC<CreateNewDirectionInterface> = ({open, close}) => {
  const dateStringRef = useRef<string[]>();
  const {push} = useHistory()
  const [form] = Form.useForm<FormInterface>()

  const closeFunc = () => {
    dateStringRef.current = []
    form.resetFields()
    close()
  }

  const {data, isLoading} = useFacultyAll()
  const createDirection = useCreateDirectionMutation(push, closeFunc)


  const onFinish = (val: FormInterface) => {
    createDirection.mutate({
      direction_id: val.direction_id,
      date: dateStringRef?.current ? dateStringRef?.current[0] : '',
      date_end: dateStringRef?.current ? dateStringRef?.current[1] : ''
    })
  }

  return (
    <Modal
      visible={open}
      className={'saveModal'}
      title={'Yangi yo‘nalish yaratish'}
      onCancel={closeFunc}
      closeIcon={<CloseModal/>}
      footer={false}
    >
      <Form
        form={form}
        name="createDirection"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        initialValues={{remember: true}}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className={'editInputsCont'}>
          <label style={{width: '17%', transform: 'translateY(-12px)'}}>
            Yo'nalish:
          </label>
          <Form.Item
            name="direction_id"
            rules={[{required: true, message: "Yo'nalish tanlanmagan!"}]}
          >
            <Select placeholder={'Tanlash'} style={{width: '390px'}} loading={isLoading}>
              {data?.map((item) => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className={'editInputsCont'}>
          <label style={{width: '17%', transform: 'translateY(-12px)'}}>
            Sana:
          </label>
          <Form.Item
            name="date"
            style={{width: '70%'}}
            rules={[{required: true, message: 'Sana kiritilmagan!'}]}
          >
            <RangePicker
              placeholder={['Boshlanish','Tugash']}
              onChange={(date, dateString) => {
                dateStringRef.current = dateString
              }}
              format={'MM.YYYY'}
              picker="month"/>
          </Form.Item>
        </div>
        <div className={'footer'}>
          <Button
            loading={createDirection.isLoading}
            shape="round"
            type={'primary'}
            className={'saveButtonCont'}
            htmlType={'submit'}>
            <SaveButtonIcon/>
            <span>
              Saqlash
            </span>
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CreateNewDirection