export const queryNames = {
  COMMISSION_LIST: 'COMMISSION_LIST',
  COMMISSION_LIST_ATTACH: 'COMMISSION_LIST_ATTACH',
  LEADER_LIST: 'LEADER_LIST',
  FACULTIES_LIST: 'FACULTIES_LIST',
  GROUP_LIST: 'GROUP_LIST',
  GROUP_LIST_STUDENT: 'GROUP_LIST_STUDENT',
  GROUP_DATE: 'GROUP_DATE',
  ALL: 'ALL',
  COMMISSION_NAVBAR: 'COMMISSION_NAVBAR',
  ONE_STUDENT_BALL: 'ONE_STUDENT_BALL',
  ONE_STUDENT_INFO: 'ONE_STUDENT_INFO'
}
