import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useAppSelector} from '../utils/helpers/customizedHooks'
import {rootPaths} from '../utils/routes/rootPaths'
import '../pages/commission/styles.scss'
import { commissionRoutes } from '../utils/routes/commissionRoutes'
import CommissionNavbar from '../components/navbars/commission/CommissionNavbar'
import UniversalHeader from '../components/header/UniversalHeader'

const CommissionRouter = () => {
  const role = useAppSelector((state) => state.user.user?.role)

  if (role !== 'commission') return <Redirect to={'/404'} />

  return (
    <>
      <UniversalHeader showSetting={false} showInput={true}/>
      <div className={'commission'}>
        <CommissionNavbar/>
        <div className={'commission_main'}>
          <Switch>
            {commissionRoutes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={rootPaths.commission + route.path}
                  component={route.component}
                  exact={route.exact}
                />
              )
            })}
          </Switch>
        </div>
      </div>
    </>
  )
}

export default CommissionRouter
