import React from "react";
import {Col, Row} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {useAppSelector} from "../../../utils/helpers/customizedHooks";

const LeaderHeader: React.FC<{ isFetching: boolean }> = ({isFetching}) => {
  const role = useAppSelector((state) => state.user.user?.role)
  return (
    <Row gutter={[8, 8]} className={'tableHeader'}>
      <Col span={role === 'director' ? 14 : 10} className={'item name'}>
        F.I.O
        {isFetching && <LoadingOutlined/>}
      </Col>
      <Col span={4} className={'item'}>
        Lavozimi
      </Col>
      <Col span={6} className={'item'}>
        Telefon raqami
      </Col>
      {role !== 'director' &&
      <Col span={4} className={'item'}>
        Action
      </Col>
      }
    </Row>
  )
}

export default LeaderHeader