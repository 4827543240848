import {useQuery} from "react-query";
import {queryNames} from "../utils/constants/queryNames";
import $api from "../services/ApiService";
import {apis} from "../utils/constants/api";
import {IFacultiesResponse} from "../utils/models/response/IFacultiesResponse";
import {IUserResponse} from "../utils/models/response/IUserResponse";
import {IGroup, IOneGroup} from "../utils/models/IGroup";
import {IFaculty} from "../utils/models/IFaculty";
import {ICommissionAttachResponse} from "../utils/models/response/ICommissionAttachResponse";
import {ICommissionNavbarRes} from "../utils/models/response/ICommissionNavbarRes";
import {IStudentBallRes} from "../utils/models/response/IStudentBallRes";
import {ICriteriaArrForEvaluateModal, IStudentInfoRes} from "../utils/models/response/IStudentInfoRes";

export function useSettingMembers(page: number) {
  const url = `?page=${page}`
  return useQuery(
    [queryNames.COMMISSION_LIST, page],
    async () => {
      const res = await $api.get<IUserResponse>(apis.COMMISSION_LIST + url)
      return res.data
    }
  )
}

export function useSettingFaculties(page: number) {
  const url = `?page=${page}`
  return useQuery(
    [queryNames.FACULTIES_LIST, page],
    async () => {
      const res = await $api.get<IFacultiesResponse>(apis.FACULTY + url)
      return res.data
    }
  )
}

export function useSettingLeader(page: number) {
  const url = `?page=${page}`
  return useQuery(
    [queryNames.LEADER_LIST, page],
    async () => {
      const res = await $api.get<IUserResponse>(apis.LEADER_LIST + url)
      return res.data
    }
  )
}

export function useGroupList(date: string) {
  return useQuery(
    [queryNames.GROUP_LIST,date],
    async () => {
      const dateArr = date.split(' - ')
      let path = ''
      if(dateArr[0] !== 'new') {
        path = `?date=${dateArr[0]}&date_end=${dateArr[1]}`
      }
      const res = await $api.get<IGroup[]>(apis.GROUP + path)
      return res.data
    }
  )
}

export function useGroupListDates() {
  return useQuery(
    [queryNames.GROUP_DATE],
    async () => {
      const res = await $api.get<string[]>(apis.GROUP_DATE)
      return res.data
    }
  )
}


export function useGroupOne(id: string) {
  return useQuery(
    [queryNames.GROUP_LIST_STUDENT, parseInt(id)],
    async () => {
      const res = await $api.get<IOneGroup>(`${apis.GROUP}/${id}`)
      return res.data
    }
  )
}

export function useFacultyAll() {
  return useQuery(
    [queryNames.FACULTIES_LIST, queryNames.ALL],
    async () => {
      const res = await $api.get<IFaculty[]>(apis.FACULTY_ALL)
      return res.data
    }
  )
}

export function useSelectedCommission(id: number) {
  return useQuery(
    [queryNames.COMMISSION_LIST_ATTACH, id],
    async () => {
      const res = await $api.get<ICommissionAttachResponse>(`${apis.COMMISSION_ATTACH}/${id}`)
      return res.data
    }
  )
}

export function useCommissionNavbar() {
  return useQuery(
    [queryNames.COMMISSION_NAVBAR],
    async () => {
      const res = await $api.get<ICommissionNavbarRes[]>(apis.MY_GROUP)
      return res.data
    }
  )
}

export function useOneStudentBall(id: string) {
  return useQuery(
    [queryNames.ONE_STUDENT_BALL, id],
    async () => {
      const res = await $api.get<IStudentBallRes>(`${apis.STUDENT_BALL}/${id}`)
      return res.data
    }
  )
}

export function useStudentInfo(id: number, open: boolean) {
  return useQuery(
    [queryNames.ONE_STUDENT_INFO, id],
    async () => {
      const res = await $api.get<IStudentInfoRes>(`${apis.STUDENT_INFO}/${id}`)
      const commissionList: string[] = []
      const ballList: ICriteriaArrForEvaluateModal[] = []
      for (let i = 0; i < res.data.criterias[0].criteria.length; i++) {
        ballList.push({
          criteria: [],
          name: '',
          description: ''
        })
      }
      res.data.criterias.forEach((item) => {
        commissionList.push(item.commission_name)
        item.criteria.forEach((criteria,index) => {
          ballList[index] = {
            ...ballList[index],
            name: criteria.criteria_name,
            description: criteria.description
          }
          ballList[index].criteria.push(criteria)
        })
      })
      return {
        allData: res.data,
        commissionList: commissionList,
        ballList: ballList
      }
    },
    {
      enabled: open,
      refetchOnWindowFocus: false
    }
  )
}