import React from "react";
import {Col, Row} from "antd";

const TableHeader = () => {
  return (
    <Row gutter={[8, 8]} className={'tableHeader'}>
      <Col span={1} className={'item'}>
        Holati
      </Col>
      <Col span={7} className={'item'}>
        F.I.O
      </Col>
      <Col span={4} className={'item'}>
        Umumiy ball
      </Col>
      <Col span={5} className={'item'}>
        Bitiruv ishi
      </Col>
      <Col span={5} className={'item'}>
        Taqdimot
      </Col>
      <Col span={2} className={'item'} />
    </Row>
  )
}

export default TableHeader