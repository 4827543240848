import React from 'react'

export const Return = () => {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.9995 -0.000244141H7.56592C7.22088 0.00115538 6.88198 0.0911541 6.58171 0.261126C6.28144 0.431098 6.02985 0.675345 5.85107 0.970459L0.142089 10.4851C0.0491026 10.6407 0 10.8185 0 10.9998C0 11.181 0.0491026 11.3588 0.142089 11.5144L5.85107 21.0291V21.03C6.02989 21.325 6.2815 21.5692 6.58178 21.739C6.88207 21.9088 7.22095 21.9986 7.56592 21.9998H25.9995C26.5298 21.9992 27.0382 21.7884 27.4131 21.4134C27.7881 21.0384 27.999 20.53 27.9995 19.9998V1.99976C27.999 1.46948 27.7881 0.96108 27.4131 0.586121C27.0382 0.211161 26.5298 0.00027939 25.9995 -0.000244141ZM19.7065 13.2927C19.7996 13.3855 19.8734 13.4958 19.9237 13.6171C19.9741 13.7385 20.0001 13.8686 20.0002 14C20.0002 14.1314 19.9744 14.2615 19.9241 14.3829C19.8739 14.5043 19.8002 14.6146 19.7073 14.7075C19.6144 14.8005 19.5041 14.8741 19.3827 14.9244C19.2613 14.9746 19.1311 15.0005 18.9997 15.0004C18.8683 15.0003 18.7382 14.9744 18.6169 14.924C18.4955 14.8736 18.3853 14.7998 18.2925 14.7068L15.9995 12.4138L13.7065 14.7068C13.519 14.894 13.2647 14.999 12.9997 14.9989C12.7347 14.9988 12.4806 14.8934 12.2932 14.706C12.1058 14.5187 12.0005 14.2645 12.0004 13.9995C12.0002 13.7345 12.1053 13.4803 12.2925 13.2927L14.5854 10.9998L12.2925 8.70679C12.1053 8.5192 12.0002 8.26498 12.0004 7.99998C12.0005 7.73497 12.1058 7.48086 12.2932 7.29348C12.4806 7.10609 12.7347 7.00076 12.9997 7.00062C13.2647 7.00047 13.519 7.10554 13.7065 7.29272L15.9995 9.58569L18.2925 7.29272C18.4801 7.10554 18.7343 7.00047 18.9993 7.00062C19.2643 7.00076 19.5184 7.10609 19.7058 7.29348C19.8932 7.48086 19.9985 7.73497 19.9987 7.99998C19.9988 8.26498 19.8937 8.5192 19.7065 8.70679L17.4136 10.9998L19.7065 13.2927Z"
        fill="#3F4B57"
      />
    </svg>
  )
}
