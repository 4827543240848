import React from "react";
import {Button, Modal, Spin} from "antd";
import {useGroupListDates} from "../../queries/queries";
import {useHistory} from "react-router";
import {rootPaths} from "../../utils/routes/rootPaths";
import CloseModal from "../../assets/svg/CloseModal";
import {LoadingOutlined} from "@ant-design/icons";

interface InterfaceChooseDirectionTimeModal {
  open: boolean,
  close: () => void
}

const ChooseDirectionTimeModal: React.FC<InterfaceChooseDirectionTimeModal> = ({open, close}) => {
  const {push} = useHistory()
  const {data, isLoading,isFetching} = useGroupListDates()

  const goToSelectSection = (date: string) => {
    close()
    push(rootPaths.main + `/${date}`)
  }
  return (
    <Modal
      className="dataModal"
      onCancel={close}
      title={
        <div>
          Guruhlarni vaqt bo'yicha saralash
          {isFetching && <LoadingOutlined/>}
        </div>
      }
      visible={open}
      footer={null}
      closeIcon={<CloseModal/>}
    >
      {isLoading ?
        <Spin
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '2rem 0'
          }}/> :
        <div className="dataModal_in">
          {data?.map((date, index) => (
            <Button type={'primary'} onClick={() => goToSelectSection(date)} key={index}>{date}</Button>
          ))}
        </div>
      }
    </Modal>
  )
}

export default ChooseDirectionTimeModal