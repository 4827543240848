import React from "react";

const Mark = () => {
  return (
    <svg className={'mark'} width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.80553 2.03676C8.21528 1.83307 8.69685 1.77247 9.17351 1.90019C10.2108 2.17813 10.8264 3.24434 10.5484 4.28164L8.96677 10.1845C8.68883 11.2218 7.62262 11.8374 6.58532 11.5594C5.54803 11.2815 4.93245 10.2153 5.21039 9.178L5.84306 6.81685C5.91453 6.55012 5.75624 6.27595 5.48951 6.20448C5.22278 6.133 4.94861 6.2913 4.87714 6.55803L4.24447 8.91918C3.82358 10.4899 4.75574 12.1045 6.3265 12.5254C7.89727 12.9463 9.51181 12.0141 9.9327 10.4433L11.5144 4.54046C11.9353 2.9697 11.0031 1.35515 9.43233 0.934265C8.71136 0.741081 7.97997 0.833305 7.36039 1.1413C6.98047 1.33016 6.64179 1.60045 6.37211 1.93667C6.19932 2.15208 6.23388 2.46677 6.44929 2.63955C6.6647 2.81233 6.97939 2.77778 7.15217 2.56237C7.32989 2.34081 7.55374 2.16193 7.80553 2.03676Z" fill="#3F4B57"/>
      <path d="M3.48191 6.18418C3.75985 5.14688 4.82606 4.53131 5.86336 4.80925C6.90065 5.08719 7.51623 6.1534 7.23829 7.1907L6.60562 9.55185C6.53415 9.81858 6.69244 10.0928 6.95917 10.1642C7.22591 10.2357 7.50007 10.0774 7.57154 9.81067L8.20421 7.44952C8.6251 5.87876 7.69294 4.26421 6.12218 3.84332C4.55142 3.42244 2.93687 4.3546 2.51598 5.92536L0.934312 11.8282C0.513428 13.399 1.44559 15.0135 3.01635 15.4344C3.73732 15.6276 4.46871 15.5354 5.08829 15.2274C5.46821 15.0385 5.80689 14.7682 6.07658 14.432C6.24936 14.2166 6.2148 13.9019 5.99939 13.7291C5.78398 13.5564 5.46929 13.5909 5.29651 13.8063C5.1188 14.0279 4.89494 14.2068 4.64315 14.3319C4.2334 14.5356 3.75183 14.5962 3.27517 14.4685C2.23787 14.1906 1.6223 13.1244 1.90024 12.0871L3.48191 6.18418Z" fill="#3F4B57"/>
    </svg>
  )
}

export default Mark