import React from "react";
import CloseModal from "../../../assets/svg/CloseModal";
import {Button, Modal, Spin} from "antd";
import './attachCommision.scss'
import {useSelectedCommission} from "../../../queries/queries";
import {LoadingOutlined} from "@ant-design/icons";
import OneAttach from "./OneAttach";
import {useAttachMutation} from "../../../queries/mutations";

interface InterfaceAttachModal {
  open: boolean
  close: () => void
  id: number
}

const AttachModal: React.FC<InterfaceAttachModal> = ({open, close, id}) => {

  const {data, isFetching, isLoading} = useSelectedCommission(id)

  const attach = useAttachMutation(id)

  const attachAll = () => {
    attach.mutate({
      group_id: id
    })
  }

  return (
    <Modal
      visible={open}
      className={'attach'}
      title={
        <div>
          Komissiya a’zolarini biriktirish
          {isFetching && <LoadingOutlined/>}
        </div>
      }
      onCancel={close}
      closeIcon={<CloseModal/>}
      footer={false}
    >
      {isLoading ?
        <div className={'spinner'}>
          <Spin size={'large'}/>
        </div>
        :
        <>
          <div className={'header'}>
            <Button
              onClick={attachAll}
              loading={attach.isLoading}
              type={'primary'}
              shape={'round'}>
              Barchasini biriktirish
            </Button>
          </div>
          <p className={"title"}>
            Komissiya raisi
          </p>
          <div className={'body'}>
            {data?.rais.map((item,index) => (
              <OneAttach data={item} id={id} key={index} />
            ))}
          </div>
          <p className={"title"}>
            Komissiya raisi o'rinbosari
          </p>
          <div className={'body'}>
            {data?.assistant.map((item,index) => (
              <OneAttach data={item} id={id} key={index} />
            ))}
          </div>
          <p className={"title"}>
            Komissiya a'zolari
          </p>
          <div className={'body'}>
            {data?.commission.map((item,index) => (
              <OneAttach data={item} id={id} key={index} />
            ))}
          </div>
        </>
      }
    </Modal>
  )
}

export default AttachModal