import React from 'react'

export const Minus = () => {
  return (
    <svg
      className={'minus'}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.00008 17.3334C13.6025 17.3334 17.3334 13.6024 17.3334 9.00002C17.3334 4.39765 13.6025 0.666687 9.00008 0.666687C4.39771 0.666687 0.666748 4.39765 0.666748 9.00002C0.666748 13.6024 4.39771 17.3334 9.00008 17.3334ZM6.50008 8.37502C6.1549 8.37502 5.87508 8.65484 5.87508 9.00002C5.87508 9.3452 6.1549 9.62502 6.50008 9.62502H11.5001C11.8453 9.62502 12.1251 9.3452 12.1251 9.00002C12.1251 8.65484 11.8453 8.37502 11.5001 8.37502H6.50008Z"
        fill="white"
      />
    </svg>
  )
}
