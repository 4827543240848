import React from "react";
import {Col, Popconfirm, Row, Spin} from "antd";
import Pencil from "../../../assets/svg/Pencil";
import Garbage from "../../../assets/svg/Garbage";
import {IFaculty} from "../../../utils/models/IFaculty";
import {useAppSelector} from "../../../utils/helpers/customizedHooks";
import {useDeleteFacultyMutation} from "../../../queries/mutations";

interface InterfaceOneFaculty {
  data: IFaculty,
  currentPage: number,
  index: number,
  editData: (data: IFaculty) => void
}

const OneFaculty: React.FC<InterfaceOneFaculty> = ({data, currentPage, index, editData}) => {
  const role = useAppSelector((state) => state.user.user?.role)

  const deleteFaculty = useDeleteFacultyMutation(currentPage)

  const deleteFunc = () => {
    deleteFaculty.mutate(data.id)
  }

  return (
    <Row gutter={[8, 8]} className={'tableBody'}>
      <Col span={2} className={'item'}>
        {(currentPage - 1) * 10 + index + 1}
      </Col>
      <Col span={role === "director" ? 22 : 18} className={'item itemName'}>
        {data?.name}
      </Col>
      {role !== "director" &&
      <Col span={4} className={'item'}>
        <span onClick={() => editData(data)}>
          <Pencil/>
        </span>
        {deleteFaculty.isLoading ?
          <Spin/> :
          <Popconfirm
            placement="topRight"
            title={
              <>
                <div>
                  Yo'nalish o'chirishni tasdiqlash!
                </div>
                <div>
                  Eslatma: Yo'nalish o'chirilganda bu yo'nalishga tegishli hamma ma'lumot o'chadi
                </div>
              </>
            }
            onConfirm={deleteFunc}
            okText="Tasdiqlash"
            cancelText="Ortga qaytish"
          >
              <span>
                <Garbage/>
              </span>
          </Popconfirm>
        }
      </Col>
      }
    </Row>
  )
}

export default OneFaculty