import React from "react";
import './universalHeaderStyles.scss'
import logo from '../../assets/images/rateme.jpg'
import {Button, Popconfirm} from "antd";
import Settings from "../../assets/svg/Settings";
import User from "../../assets/svg/User";
import {useAppSelector} from "../../utils/helpers/customizedHooks";
import {useHistory} from "react-router";
import {settingsPaths} from "../../utils/routes/settingsRouter";
import {rootPaths} from "../../utils/routes/rootPaths";
import LogOut from "../../assets/svg/LogOut";
import {AuthService} from "../../services/AuthService";

interface InterfaceUniversalHeader {
  showInput: boolean,
  showSetting: boolean
}

const UniversalHeader: React.FC<InterfaceUniversalHeader> = ({showInput, showSetting}) => {
  const {push} = useHistory()
  const name = useAppSelector((state) => state.user.user?.name)

  const goToHome = () => {
    push(rootPaths.main+'/new')
  }

  return (
    <div className={'universalHeaderCont'}>
      <div className={'left'}>
        <div className={'logoCont'}>
          <img alt={'RateMe'} src={logo}/>
          <span>
            RateMe
          </span>
        </div>
        {!showInput &&
          <Button
            onClick={goToHome}
            className={'homeButton'}
            type={'link'}>
            Bosh sahifa
          </Button>
        }
      </div>
      <div className={'right'}>
        {showSetting &&
        <span
          onClick={() => {
            push(rootPaths.settings + settingsPaths.members)
          }}
          title={"Sozlamalar bo'limi"}
          className={'icon'}>
          <Settings/>
        </span>
        }
        <span className={'icon'}>
          <User/>
        </span>
        <span className={'name'}>
          {name}
        </span>
        <Popconfirm
          placement="bottomRight"
          title={"Tizimdan chiqishni tasdiqlash!"}
          onConfirm={() => {
            AuthService.logout()
          }}
          okText="Tasdiqlash"
          cancelText="Ortga qaytish"
        >
        <span
          title={'Tizimdan chiqish'}
          className={'icon'}>
          <LogOut/>
        </span>
        </Popconfirm>
      </div>
    </div>
  )
}

export default UniversalHeader