import React from "react";
import {IStudent} from "../../../utils/models/IStudent";
import GraduationCap from "../../../assets/svg/GraduationCap";
import TableHeader from "./TableHeader";
import OneStudent from "./OneStudent";

interface InterfaceStudentsList {
  students: IStudent[],
  id: number
  date: string
}

const StudentsList: React.FC<InterfaceStudentsList> = ({students, id,date}) => {
  if (students.length === 0)
    return (
      <div className={'empty'}>
        <div>
          <GraduationCap/>
        </div>
        <div className={'empty_title'}>
          Tinglovchilar mavjud emas!
        </div>
      </div>
    )
  return (
    <div className={'table'}>
      <TableHeader/>
      {students.map((student, index) => (
        <OneStudent date={date} id={id} student={student} index={index} key={index}/>
      ))}
    </div>
  )
}

export default StudentsList