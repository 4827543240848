import React from "react";

const Repeate = () => {
  return (
    <svg className={'repeate'} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3146 7.14765C13.4882 6.97408 13.4882 6.69267 13.3146 6.51911C13.1411 6.34554 12.8597 6.34554 12.6861 6.51911L12.1115 7.09373L12.1115 5.90151C12.1116 4.75485 12.1117 4.06911 11.9266 3.49931C11.5529 2.34921 10.6512 1.44752 9.50111 1.07383C8.93131 0.888692 8.24557 0.888779 7.09891 0.888924L6.90185 0.888924C5.75519 0.888778 5.06945 0.888692 4.49965 1.07383C4.2662 1.14968 4.13845 1.40042 4.2143 1.63386C4.29015 1.86731 4.54088 1.99506 4.77433 1.91921C5.19262 1.7833 5.72493 1.77782 7.00038 1.77782C8.27583 1.77782 8.80814 1.7833 9.22643 1.91921C10.1059 2.20498 10.7954 2.89451 11.0812 3.77399C11.2171 4.19228 11.2226 4.7246 11.2226 6.00004L11.2226 6.93913L10.7585 6.38218C10.6013 6.19362 10.3211 6.16814 10.1325 6.32528C9.94395 6.48242 9.91848 6.76267 10.0756 6.95124L10.8583 7.89042C11.0616 8.13441 11.3467 8.26819 11.6373 8.28771C11.9437 8.3083 12.2603 8.20197 12.4975 7.96478L13.3146 7.14765Z" fill="#649BD3"/>
      <path d="M9.79922 11.5946C10.0336 11.5216 10.1644 11.2724 10.0914 11.0381C10.0184 10.8037 9.76923 10.6729 9.53487 10.7459C9.09196 10.8839 8.52942 10.8889 7.2017 10.8889C5.87398 10.8889 5.31144 10.8839 4.86853 10.7459C3.94015 10.4567 3.22128 9.76212 2.92482 8.88743C2.78407 8.47218 2.77816 7.94345 2.77816 6.66671L2.77816 5.73969L3.35278 6.31431C3.52634 6.48788 3.80775 6.48788 3.98132 6.31431C4.15488 6.14075 4.15488 5.85934 3.98132 5.68577L3.16419 4.86864C2.92699 4.63145 2.6104 4.52512 2.30392 4.54571C2.01337 4.56523 1.72826 4.69901 1.52493 4.943L0.742282 5.88218C0.585143 6.07075 0.61062 6.351 0.799187 6.50814C0.987756 6.66528 1.26801 6.6398 1.42515 6.45124L1.88927 5.89429L1.88926 6.76786C1.8891 7.9119 1.889 8.60048 2.08297 9.17277C2.47441 10.3277 3.41541 11.2243 4.60418 11.5946C5.19317 11.778 5.90289 11.778 7.10493 11.7778L7.29847 11.7778C8.50051 11.778 9.21023 11.778 9.79922 11.5946Z" fill="#649BD3"/>
    </svg>
  )
}

export default Repeate