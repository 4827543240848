import React from "react";
import Plus from "../../assets/svg/Plus";

interface InterfaceEditButtonMember {
  openEditModal: () => void,
  title: string
}

const EditButton: React.FC<InterfaceEditButtonMember> = ({openEditModal,title}) => {
  return (
    <div
      onClick={openEditModal}
      className={'createButton'}>
      <Plus />
      <span>
        {title}
      </span>
    </div>
  )
}

export default EditButton