import React from 'react'
import './Home.scss'
import HomeHeader from "./HomeHeader";
import {useParams} from "react-router-dom";
import {useGroupOne} from "../../../queries/queries";
import {Spin} from "antd";
import StudentsList from "./StudentsList";
import {useHistory} from "react-router";
import {rootPaths} from "../../../utils/routes/rootPaths";

const Home = () => {
  const {id,date} = useParams<{id: string,date: string}>()
  const {push} = useHistory()

  if(!parseInt(id))
    push(rootPaths.fof)

  const {data,isLoading,isFetching} = useGroupOne(id)

  if(isLoading)
    return (
      <div className={'main'}>
        <Spin className={'spinner'} size={'large'} />
      </div>
    )

  return (
    <div className={'main'}>
      <HomeHeader date={date} id={data!.id} name={data!.name} count={data!.student_count} isFetching={isFetching} />
      <StudentsList date={date} id={data!.id} students={data!.students} />
    </div>
  )
}

export default Home
