import React from 'react'
import {Col, Popconfirm, Row, Spin, Switch} from 'antd'
import Pencil from '../../../assets/svg/Pencil'
import Garbage from '../../../assets/svg/Garbage'
import { User } from '../../../utils/models/User'
import {useChangeStatusUserMutation, useDeleteCommissionMutation} from '../../../queries/mutations'
import { useAppSelector } from '../../../utils/helpers/customizedHooks'
import Avatar from './../../../assets/svg/Avatar'
interface InterfaceOneMember {
  data: User
  editData: (data: User) => void
  page: number
}

const OneMember: React.FC<InterfaceOneMember> = ({ data, editData, page }) => {
  const role = useAppSelector((state) => state.user.user?.role)

  const changeStatus = useChangeStatusUserMutation(page)
  const deleteCommission = useDeleteCommissionMutation(page)

  const changeStatusFunc = (status: string) => {
    changeStatus.mutate({
      id: data.id as number,
      status,
    })
  }

  const deleteFunc = () => {
    deleteCommission.mutate(data.id || 0)
  }

  return (
    <Row gutter={[8, 8]} className={'tableBody'}>
      <Col span={role === 'director' ? 14 : 10} className={'item nameUser'}>
        <div className="avatarIcon">
          <Avatar />
        </div>
        {data.name}
      </Col>
      <Col span={6} className={'item'}>
        {data.phone}
      </Col>
      <Col span={4} className={'item'}>
        <Switch
          disabled={role === 'director'}
          loading={changeStatus.isLoading}
          onChange={(e) => {
            if (!e) changeStatusFunc('passive')
            else changeStatusFunc('active')
          }}
          checked={data?.status === 'active'}
        />
      </Col>
      {role !== 'director' && (
        <Col span={4} className={'item'}>
          <span onClick={() => editData(data)}>
            <Pencil />
          </span>
          {deleteCommission.isLoading ?
            <Spin/> :
            <Popconfirm
              placement="topRight"
              title={
                <>
                  <div>
                    Kommissiya a'zosini o'chirishni tasdiqlash!
                  </div>
                </>
              }
              onConfirm={deleteFunc}
              okText="Tasdiqlash"
              cancelText="Ortga qaytish"
            >
              <span>
                <Garbage/>
              </span>
            </Popconfirm>
          }
        </Col>
      )}
    </Row>
  )
}

export default OneMember
