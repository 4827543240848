import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useAppSelector } from '../utils/helpers/customizedHooks'
import { mainRoutes } from '../utils/routes/mainRoutes'
import { rootPaths } from '../utils/routes/rootPaths'
import UniversalHeader from "../components/header/UniversalHeader";
import HomeNavbar from "../components/navbars/HomeNavbar";
import '../pages/main/home/Home'

interface MainRouterProps {}

const MainRouter: React.FC<MainRouterProps> = () => {
  const role = useAppSelector((state) => state.user.user?.role)

  if(role !== 'admin' && role !== 'director')
    return <Redirect to={'/404'} />

  return (
    <>
      <UniversalHeader showSetting={true} showInput={true} />
      <div className={"homePage"}>
        <HomeNavbar />
        <Switch>
          {mainRoutes.map((route,index) => (
            <Route
              key={index}
              path={rootPaths.main + route.path}
              component={route.component}
              exact={route.exact}
            />
          ))}
        </Switch>
      </div>
    </>
  )
}

export default MainRouter
