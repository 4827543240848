import React, {useEffect, useState} from 'react'
import {Button, Input, message, Modal, Spin} from 'antd'
import CloseModal from "../../../assets/svg/CloseModal";
import {useStudentInfo} from "../../../queries/queries";
import {LoadingOutlined} from "@ant-design/icons";
import {useAppSelector} from "../../../utils/helpers/customizedHooks";
import {
  useDownloadStudentChangeFileMutation,
  useEvaluationDirectorMutation,
  useSaveStudentChangeFileMutation
} from "../../../queries/mutations";
import Repeate from "../../../assets/svg/Repeate";
import GarbageMini from "../../../assets/svg/GarbageMini";
import Mark from "../../../assets/svg/Mark";
import dayjs from "dayjs";
import {ICriteriaArrForEvaluateModal} from "../../../utils/models/response/IStudentInfoRes";

interface IModalEvaluate {
  isModalVisible: boolean,
  close: React.Dispatch<React.SetStateAction<boolean>>,
  name: string,
  id: number
}

const ModalEvaluate: React.FC<IModalEvaluate> = ({isModalVisible, close, name, id}) => {
  const [ballsList, setBallsList] = useState<{
    list: ICriteriaArrForEvaluateModal[],
    change: {
      id: number
      ball: number
    }[]
  }>({
    change: [],
    list: []
  });

  const role = useAppSelector((state) => state.user.user?.role)

  const {isLoading, data, isFetching} = useStudentInfo(id, isModalVisible)

  const changeBall = useEvaluationDirectorMutation(id)
  const downloadFile = useDownloadStudentChangeFileMutation(data?.allData?.file_name || '')
  const uploadFile = useSaveStudentChangeFileMutation(id)

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setBallsList({
        change: [],
        list: data?.ballList || []
      })
    }
    // eslint-disable-next-line
  }, [isFetching, isLoading]);

  const changeBallFunc = () => {
    changeBall.mutate(ballsList?.change)
  }

  const closeFunc = () => {
    close(false)
  }

  const calculateAllBal = () => {
    return Math.floor(((data?.allData?.online_test_ball || 0) + (data?.allData.average_value || 0) + (data?.allData?.professional_ball || 0)) * 100) / 100
  }

  return (
    <div>
      <Modal
        className="modalEvaluate"
        title={
          <div>
            {name}
            {isFetching && <LoadingOutlined/>}
          </div>
        }
        centered={true}
        closeIcon={<CloseModal/>}
        width={756}
        footer={null}
        onCancel={closeFunc}
        visible={isModalVisible}
      >
        {isLoading ?
          <Spin size={"large"} className={'spinner'}/>
          :
          <>
            <table
              className="tableEvaluate">
              <tr>
                <td style={{textAlign: 'center', height: '61px', padding: '0px '}}>
                  Komissiya a'zolari
                </td>
                <td style={{textAlign: 'center'}}>Bitiruv ishi himoyasi natijasi</td>
              </tr>
              <tr>
                <td
                  className="commission_list"
                  style={{padding: '10px 20px'}}
                >
                  {data?.commissionList.map((commission, index) => (
                    <p key={index}>
                      {commission}
                    </p>
                  ))}
                </td>
                <td
                  style={{
                    display: 'flex',
                    border: 'none',
                    padding: '10px 0',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                  }}
                >
                  {ballsList.list.map((item, index) => (
                    <td
                      key={index}
                      style={{
                        border: 'none',
                        width: '25%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        title={item.description}
                        style={{marginBottom: '0'}}>
                        {item.name}
                      </p>
                      {item?.criteria.map((criteria, i) => {
                        let isChange: boolean = false
                        const change = [...ballsList.change]
                        const findBallIndex = change.findIndex((ball) => ball.id === criteria.ball_id)
                        if (findBallIndex > -1) {
                          isChange = true
                        }
                        return (
                          <Input
                            disabled={role !== 'director' || criteria.status === 'low'}
                            onChange={(e: any) => {
                              const value = parseFloat(e.target.value)
                              if (!e.target.value) {
                                const change = [...ballsList.change]
                                const list = [...ballsList.list]
                                const findBallIndex = change.findIndex((ball) => ball.id === criteria.ball_id)
                                if (findBallIndex > -1) {
                                  change[findBallIndex].ball = 0
                                } else {
                                  change.push({
                                    id: criteria.ball_id || -1,
                                    ball: 0
                                  })
                                }
                                list[index].criteria[i].ball = 0
                                setBallsList({
                                  change,
                                  list
                                })
                              } else if (value < 0) {
                                if (-value < criteria.max_ball) {
                                  const floorVal = Math.floor(value*100)/100
                                  const change = [...ballsList.change]
                                  const list = [...ballsList.list]
                                  const findBallIndex = change.findIndex((ball) => ball.id === criteria.ball_id)
                                  if (findBallIndex > -1) {
                                    change[findBallIndex].ball = - floorVal
                                  } else {
                                    change.push({
                                      id: criteria.ball_id || -1,
                                      ball: -floorVal
                                    })
                                  }
                                  list[index].criteria[i].ball = -floorVal
                                  setBallsList({
                                    change,
                                    list
                                  })
                                } else {
                                  message.error("Ball 0 dan kichkina!")
                                }
                              } else if (value > criteria.max_ball) {
                                message.error(`Ball ${criteria.max_ball} dan katta!`)
                              } else {
                                const floorVal = Math.floor(value*100)/100
                                const change = [...ballsList.change]
                                const list = [...ballsList.list]
                                const findBallIndex = change.findIndex((ball) => ball.id === criteria.ball_id)
                                if (findBallIndex > -1) {
                                  change[findBallIndex].ball = floorVal
                                } else {
                                  change.push({
                                    id: criteria.ball_id || -1,
                                    ball: floorVal
                                  })
                                }
                                list[index].criteria[i].ball = floorVal
                                setBallsList({
                                  change,
                                  list
                                })
                              }
                            }}
                            style={{
                              marginTop: '8px',
                              color: isChange ? '#fe0000' : criteria.status === 'passive' ? "#649BD3" : '#282828'
                            }}
                            key={i}
                            type={'number'}
                            min={0}
                            placeholder={"0"}
                            value={criteria.ball === 0 ? undefined : criteria.ball}
                            max={criteria.max_ball}/>
                        )
                      })}
                    </td>
                  ))}
                </td>
              </tr>
              <tr>
                <td style={{height: '60px', paddingLeft: '20px'}}>Jami ball</td>
                <td style={{textAlign: 'center'}}>{data?.allData.average_value}ball</td>
              </tr>
              <tr>
                <td style={{height: '60px', paddingLeft: '20px'}}>
                  Kasbiy faoliyatidagi ko'rsatgichi
                </td>
                <td style={{textAlign: 'center'}}>{data?.allData?.professional_ball}ball</td>
              </tr>
              <tr>
                <td style={{height: '60px', paddingLeft: '20px'}}>
                  Online test sinovlari natijalari
                </td>
                <td style={{textAlign: 'center'}}>{data?.allData?.online_test_ball}ball</td>
              </tr>
              <tr>
                <td style={{height: '60px', paddingLeft: '20px'}}>Umumiy ball</td>
                <td style={{textAlign: 'center'}}>{calculateAllBal()}ball</td>
              </tr>
            </table>
            {role === 'director' &&
            <div className={'footer'}>
              <div className={'date'}>
                {data?.allData.date &&
                <>
                <span className={'date_label'}>
                  O’zgartirildi:
                </span>
                  <span className={'date_main'}>
                {dayjs.unix(parseInt(data.allData.date)).format('DD.MM.YYYY HH:mm').toString()}
                  </span>
                </>
                }
              </div>
              <div className={'footer_right'}>
                {
                  data?.allData?.file ?
                    <div className={'repeateCont'}>
                      <span className={'fileLabel'}>
                        Asos:
                      </span>
                      <span
                        title={'Asos fileni yuklab olish'}
                        onClick={() => downloadFile.mutate(id)}
                        className={'fileName'}>
                        {data?.allData?.file_name}
                      </span>
                      <label
                        htmlFor={'fileDownloadChange'}
                        className={'changeFileButton'}>
                        <Repeate/>
                        <GarbageMini/>
                      </label>
                    </div>
                    :
                    <label
                      htmlFor={'fileDownloadChange'}
                      className={'addFileButton'}>
                      <Mark/>
                      <span>
                        Asos hujjat
                      </span>
                    </label>
                }
                <input
                  style={{
                    display: 'none'
                  }}
                  onChange={(e) => {
                    console.log(e.target.files)
                    if (e.target.files && e.target.files.length === 1) {
                      const formData = new FormData()
                      formData.append('student_id', id as any)
                      formData.append('file', e.target.files[0])
                      uploadFile.mutate(formData)
                    }
                  }}
                  id={'fileDownloadChange'}
                  accept={'.doc,.docx,.pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                  type={'file'}/>
                <Button
                  onClick={changeBallFunc}
                  loading={changeBall.isLoading}
                  disabled={ballsList.change.length === 0}
                  shape={'round'}
                  type={'primary'}
                  className={'button'}>
                  Bahoni o'zgartirish
                </Button>
              </div>
            </div>
            }
          </>
        }
      </Modal>
    </div>
  )
}

export default ModalEvaluate
