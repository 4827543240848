import React from "react";

const Plus = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.7869 22.377C18.0833 22.377 22.377 18.0833 22.377 12.7869C22.377 7.49041 18.0833 3.19678 12.7869 3.19678C7.49041 3.19678 3.19678 7.49041 3.19678 12.7869C3.19678 18.0833 7.49041 22.377 12.7869 22.377Z" stroke="#636363" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.79102 12.7869H16.7828" stroke="#636363" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.7869 8.79102V16.7828" stroke="#636363" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default Plus

