import React from "react";
import {Col, Row} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {useAppSelector} from "../../../utils/helpers/customizedHooks";

const HeaderTable: React.FC<{ isFetching: boolean }> = ({isFetching}) => {
  const role = useAppSelector((state) => state.user.user?.role)
  return (
    <Row gutter={[8, 8]} className={'tableHeader'}>
      <Col span={2} className={'item'}>
        №
        {isFetching && <LoadingOutlined/>}
      </Col>
      <Col span={role === 'director' ? 22 : 18} className={'item'}>
        Nomi
      </Col>
      {role !== "director" &&
      <Col span={4} className={'item'}>
        Action
      </Col>
      }
    </Row>
  )
}
export default HeaderTable