import React from "react";
import {AttachItem} from "../../../utils/models/response/ICommissionAttachResponse";
import {Avatar, Switch} from "antd";
import {useAttachMutation} from "../../../queries/mutations";

interface InterfaceOneAttach {
  data: AttachItem
  id: number
}

const OneAttach:React.FC<InterfaceOneAttach> = ({data,id}) => {

  const attach = useAttachMutation(id)

  const attachFunc = () => {
    attach.mutate({
      group_id: id,
      user_id: data.id
    })
  }

  return (
    <div className={'body_one'}>
      <div className={'body_one_left'}>
        <Avatar>
          {data.name?.slice(0,1)}
        </Avatar>
        <p>
          {data.name}
        </p>
      </div>
      <Switch
        onChange={attachFunc}
        loading={attach.isLoading}
        checked={data.select} />
    </div>
  )
}

export default OneAttach