import React from "react";
import {Pagination, PaginationProps} from "antd";

interface PaginationInterface {
  paginationProps?: PaginationProps
}

const PaginationComp:React.FC<PaginationInterface> = ({paginationProps}) => {
  return (
    <div className={"paginationCont"}>
      <Pagination
        showSizeChanger={false}
        {...paginationProps}/>
    </div>
  )
}

export default PaginationComp