import React from "react";

const CloseModal = () => {
  return (
    <div
      style={{
        transform: 'translateY(8px)'
      }}>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.875 6.125L6.125 21.875" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.875 21.875L6.125 6.125" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  )
}

export default CloseModal