import React from 'react';
import GraduationCap from "../../../assets/svg/GraduationCap";

function NotSelectDirection() {
  return (
    <div className={'main'}>
      <div className={'empty'}>
        <div>
          <GraduationCap/>
        </div>
        <div className={'empty_title'}>
          Guruh tanlanmagan!
        </div>
      </div>
    </div>
  );
}

export default NotSelectDirection;