import React from 'react'

export const PlusCircle = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7869 22.3769C18.0833 22.3769 22.377 18.0833 22.377 12.7868C22.377 7.49035 18.0833 3.19672 12.7869 3.19672C7.49041 3.19672 3.19678 7.49035 3.19678 12.7868C3.19678 18.0833 7.49041 22.3769 12.7869 22.3769Z"
        stroke="#EAEAEA"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.79102 12.7868H16.7828"
        stroke="#EAEAEA"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7869 8.79095V16.7827"
        stroke="#EAEAEA"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
