import MainRouter from '../../routing/MainRouter'
import { FourOFour } from '../../pages/404/FourOFour'
import { rootPaths } from './rootPaths'
import LoginPage from "../../pages/login/Login.page";
import SettingsRouter from "../../routing/SettingsRouter";
import CommissionRouter from "../../routing/CommissionRouter";
import LoginAdmin from "../../pages/login/LoginAdmin";
//

export const rootRoutes = [
  {
    path: rootPaths.main,
    component: MainRouter,
    exact: false,
  },
  {
    path: rootPaths.settings,
    component: SettingsRouter,
    exact: false,
  },
  {
    path: rootPaths.login,
    component: LoginPage,
    exact: true,
  },
  {
    path: rootPaths.fof,
    component: FourOFour,
    exact: true,
  },
  {
    path: rootPaths.commission,
    component: CommissionRouter,
    exact: false,
  },
  {
    path: rootPaths.admin_login,
    component: LoginAdmin,
    exact: true,
  }
]
