import axios from 'axios'
import { TOKEN_KEY } from '../utils/constants/localStorageKeys'
import { LocalStorage } from './LocalStorage'
import {AuthService} from "./AuthService";

const $api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})


$api.interceptors.request.use((config) => {
  const token = LocalStorage.get(TOKEN_KEY)
  if (token) {
    config.headers!.Authorization = `Bearer ${token}`
  }
  return config
})

$api.interceptors.response.use(undefined, (error) => {
  const token = LocalStorage.get(TOKEN_KEY)
  if(token) {
    if (error.response.status === 401) {
      AuthService.logout()
    }
  }
  return Promise.reject(error)
})

export default $api
