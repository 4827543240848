import Members from '../../pages/settings/members/Members'
import Leader from '../../pages/settings/leader/Leader'
import Faculties from '../../pages/settings/faculties/Faculties'

export const settingsPaths = {
  members: '/members',
  faculties: '/faculties',
  leader: '/leader',
}

export const settingsRouter = [
  {
    path: settingsPaths.members,
    component: Members,
    exact: true,
  },
  {
    path: settingsPaths.leader,
    component: Leader,
    exact: true,
  },
  {
    path: settingsPaths.faculties,
    component: Faculties,
    exact: true,
  },
]
