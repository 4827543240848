import React from "react";

const Download = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.66665C0 10.1665 0 11.4164 0.63661 12.2926C0.842209 12.5756 1.09107 12.8244 1.37405 13.03C2.25027 13.6666 3.50018 13.6666 6 13.6666C8.49982 13.6666 9.74973 13.6666 10.626 13.03C10.9089 12.8244 11.1578 12.5756 11.3634 12.2926C12 11.4164 12 10.1665 12 7.66665V6.33331C12 5.77402 12 5.27729 11.9929 4.83331L11.9461 4.83332C11.3781 4.83336 11.0321 4.83339 10.7337 4.78612C9.09341 4.52633 7.80699 3.2399 7.54719 1.59965C7.49993 1.30119 7.49995 0.955204 7.5 0.387193L7.5 0.340443C7.05602 0.333313 6.5593 0.333313 6 0.333313C3.50018 0.333313 2.25027 0.333313 1.37405 0.969922C1.09107 1.17552 0.842209 1.42438 0.63661 1.70736C0 2.58358 0 3.83349 0 6.33331V7.66665ZM6.5 4.99998C6.5 4.72384 6.27614 4.49998 6 4.49998C5.72386 4.49998 5.5 4.72384 5.5 4.99998V9.62412C5.49052 9.61673 5.48086 9.60908 5.47099 9.60116C5.25591 9.42843 5.00022 9.17152 4.62036 8.78826L3.68846 7.84801C3.49407 7.65188 3.17749 7.65046 2.98136 7.84485C2.78523 8.03924 2.78382 8.35582 2.97821 8.55195L3.93043 9.51271C4.28469 9.87016 4.58015 10.1683 4.84483 10.3809C5.12244 10.6038 5.41421 10.7734 5.76967 10.8187C5.92261 10.8382 6.07739 10.8382 6.23033 10.8187C6.58579 10.7734 6.87756 10.6038 7.15517 10.3809C7.41985 10.1683 7.71529 9.87018 8.06954 9.51274L9.0218 8.55195C9.21618 8.35582 9.21477 8.03924 9.01864 7.84485C8.82251 7.65046 8.50593 7.65188 8.31154 7.84801L7.37964 8.78826C6.99978 9.17153 6.74409 9.42843 6.52901 9.60116C6.51915 9.60908 6.50948 9.61673 6.5 9.62412V4.99998Z" fill="#3F4B57"/>
      <path d="M10.8901 3.79843C11.0957 3.83099 11.3496 3.83322 11.9564 3.83331C11.8935 2.87356 11.7396 2.2252 11.3634 1.70736C11.1578 1.42438 10.9089 1.17552 10.626 0.969922C10.1081 0.59369 9.45975 0.439809 8.5 0.37687C8.50009 0.983763 8.50232 1.23762 8.53488 1.44321C8.7269 2.65557 9.67774 3.60641 10.8901 3.79843Z" fill="#3F4B57"/>
    </svg>

  )
}

export default Download