import React from "react";

const GraduationCap = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 18L24 6L46.5 18L24 30L1.5 18Z" stroke="#3F4B57" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M35.25 45V24L24 18" stroke="#3F4B57" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M41.25 20.7999V31.0226C41.2506 31.3462 41.146 31.6612 40.952 31.9201C39.6888 33.6009 34.3599 39.7499 24 39.7499C13.6401 39.7499 8.31116 33.6009 7.04804 31.9201C6.85399 31.6612 6.74938 31.3462 6.75 31.0226V20.7999" stroke="#3F4B57" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default GraduationCap