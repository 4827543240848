import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useAppSelector } from '../utils/helpers/customizedHooks'
import { rootPaths } from '../utils/routes/rootPaths'
import { settingsRouter } from '../utils/routes/settingsRouter'
import NavbarSettings from '../pages/settings/navbar/NavbarSettings'
import UniversalHeader from '../components/header/UniversalHeader'
import '../pages/settings/settings.scss'

const SettingsRouter = () => {
  const role = useAppSelector((state) => state.user.user?.role)

  if(role !== 'admin' && role !== 'director')
    return <Redirect to={'/404'} />

  return (
    <div className={'settingsCont'}>
      <UniversalHeader showSetting={true} showInput={false} />
      <div className={'body'}>
        <div className={'body_sections'}>
          <NavbarSettings />
          <div>
            <Switch>
              {settingsRouter.map((route) => (
                <Route
                  key={route.path}
                  path={rootPaths.settings + route.path}
                  component={route.component}
                  exact={route.exact}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsRouter
