import React from "react";

const Document = () => {
  return (
    <svg className={'document'} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7.66665C12 10.1665 12 11.4164 11.3634 12.2926C11.1578 12.5756 10.9089 12.8244 10.626 13.03C9.74973 13.6666 8.49982 13.6666 6 13.6666C3.50018 13.6666 2.25027 13.6666 1.37405 13.03C1.09107 12.8244 0.842208 12.5756 0.636609 12.2926C0 11.4164 0 10.1665 0 7.66665V6.33331C0 5.77402 0 5.27729 0.00712967 4.83331L0.0538801 4.83332C0.621891 4.83336 0.967882 4.83339 1.26633 4.78612C2.90659 4.52633 4.19301 3.2399 4.45281 1.59965C4.50008 1.30119 4.50005 0.955204 4.5 0.387193L4.5 0.340443C4.94398 0.333313 5.4407 0.333313 6 0.333313C8.49982 0.333313 9.74973 0.333313 10.626 0.969922C10.9089 1.17552 11.1578 1.42438 11.3634 1.70736C12 2.58358 12 3.83349 12 6.33331V7.66665ZM6.66667 3.83331C6.39052 3.83331 6.16667 4.05717 6.16667 4.33331C6.16667 4.60946 6.39052 4.83331 6.66667 4.83331H8.66667C8.94281 4.83331 9.16667 4.60946 9.16667 4.33331C9.16667 4.05717 8.94281 3.83331 8.66667 3.83331H6.66667ZM3.33333 6.49998C3.05719 6.49998 2.83333 6.72384 2.83333 6.99998C2.83333 7.27612 3.05719 7.49998 3.33333 7.49998H8.66667C8.94281 7.49998 9.16667 7.27612 9.16667 6.99998C9.16667 6.72384 8.94281 6.49998 8.66667 6.49998H3.33333ZM6.66667 9.16665C6.39052 9.16665 6.16667 9.3905 6.16667 9.66665C6.16667 9.94279 6.39052 10.1666 6.66667 10.1666H8.66667C8.94281 10.1666 9.16667 9.94279 9.16667 9.66665C9.16667 9.3905 8.94281 9.16665 8.66667 9.16665H6.66667Z" fill="#3F4B57"/>
      <path d="M1.1099 3.79843C0.904305 3.83099 0.65045 3.83322 0.0435575 3.83331C0.106496 2.87356 0.260377 2.2252 0.636609 1.70736C0.842208 1.42438 1.09107 1.17552 1.37405 0.969922C1.89189 0.59369 2.54025 0.439809 3.5 0.37687C3.49991 0.983763 3.49768 1.23762 3.46512 1.44321C3.2731 2.65557 2.32226 3.60641 1.1099 3.79843Z" fill="#3F4B57"/>
    </svg>
  )
}

export default Document