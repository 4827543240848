import React, {useEffect, useState} from 'react'
import 'react-phone-input-2/lib/style.css'
import './Login.page.scss'
import './LoginPinCode.scss'
import {Return} from '../../assets/svg/Return'
import {useHistory} from "react-router";
import {rootPaths} from "../../utils/routes/rootPaths";
import {useChangePasswordMutation, useLoginCommissionMutation} from "../../queries/mutations";
import {useAppDispatch} from "../../utils/helpers/customizedHooks";
import {Spin} from "antd";
import {LocalStorage} from "../../services/LocalStorage";
import {TOKEN_KEY, USER_KEY} from "../../utils/constants/localStorageKeys";
import {User} from "../../utils/models/User";


const LoginPage = () => {
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const [code, setCode] = useState('');
  const [phone, setPhone] = useState('998');
  const {push} = useHistory()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const token = LocalStorage.get(TOKEN_KEY)
    const user: User = LocalStorage.get(USER_KEY) as User
    if (token) {
      if (user.role !== 'commission') {
        push(rootPaths.main + '/new')
      }
    }
    // eslint-disable-next-line
  }, [])

  const openCodeSection = () => {
    setShowPhoneInput(false)
    setPhone('')
  }

  const login = useLoginCommissionMutation(dispatch, push)
  const resetPassword = useChangePasswordMutation(openCodeSection)

  const getPhoneNumberFunc = (number: string) => {
    let newPhone = ''
    for (let i = 0; i < 12; i++) {
      if (i === 0) {
        newPhone += `+${number[i]}`
      } else if (i === 3 || i === 5 || i === 8 || i === 10) {
        newPhone += ` ${number[i]}`
      } else {
        newPhone += `${number[i]}`
      }
    }
    return newPhone
  }

  const changeFunc =  (number: string) => {
    if (showPhoneInput) {
      setPhone(phone + number)
      if((phone+number).length === 12) {
        const newPhone = getPhoneNumberFunc(phone + number)
        resetPassword.mutate({ phone: newPhone })
      }
    } else {
      setCode(code + number)
      if ((code + number).length === 9) {
        login.mutate(code + number)
      }
    }
  }

  const deleteFunc = () => {
    if (showPhoneInput) {
      if (phone.length > 3) {
        setPhone(phone.slice(0, -1))
      }
    } else {
      setCode(code.slice(0, -1))
    }
  }

  return (
    <div className="loginPinCode">
      <div className="container">
        <div className="controlPanelDiv">
          <button
            title={'Boshqaruv paneliga kirish!'}
            onClick={() => {
              push(rootPaths.admin_login)
            }}
            className="controlPanel">
            Boshqaruv paneli
          </button>
        </div>
        <div
          style={{width: showPhoneInput ? "820px" : '600px'}}
          className="codeEnter">
          {showPhoneInput ?
            <h4>
              Telefon raqamingizni kiriting
            </h4> :
            <h4>Shaxsiy PIN kodingizni kiriting</h4>
          }
          <div className="codeInput">
            {showPhoneInput ?
              <>
                <div className={phone.length === 0 ? 'active' : ''}>
                  {phone.length > 0 ? phone[0] : '|'}
                </div>
                <div className={phone.length === 1 ? 'active' : ''}>
                  {phone.length > 1 ? phone[1] : phone.length === 1 && '|'}
                </div>
                <div
                  style={{marginRight: '20px'}}
                  className={phone.length === 2 ? 'active' : ''}>
                  {phone.length > 2 ? phone[2] : phone.length === 2 && '|'}
                </div>
                <div className={phone.length === 3 ? 'active' : ''}>
                  {phone.length > 3 ? phone[3] : phone.length === 3 && '|'}
                </div>
                <div
                  style={{marginRight: '20px'}}
                  className={phone.length === 4 ? 'active' : ''}>
                  {phone.length > 4 ? phone[4] : phone.length === 4 && '|'}
                </div>
                <div className={phone.length === 5 ? 'active' : ''}>
                  {phone.length > 5 ? phone[5] : phone.length === 5 && '|'}
                </div>
                <div className={phone.length === 6 ? 'active' : ''}>
                  {phone.length > 6 ? phone[6] : phone.length === 6 && '|'}
                </div>
                <div
                  style={{marginRight: '20px'}}
                  className={phone.length === 7 ? 'active' : ''}>
                  {phone.length > 7 ? phone[7] : phone.length === 7 && '|'}
                </div>
                <div className={phone.length === 8 ? 'active' : ''}>
                  {phone.length > 8 ? phone[8] : phone.length === 8 && '|'}
                </div>
                <div
                  style={{marginRight: '20px'}}
                  className={phone.length === 9 ? 'active' : ''}>
                  {phone.length > 9 ? phone[9] : phone.length === 9 && '|'}
                </div>
                <div className={phone.length === 10 ? 'active' : ''}>
                  {phone.length > 10 ? phone[10] : phone.length === 10 && '|'}
                </div>
                <div className={phone.length === 11 ? 'active' : ''}>
                  {phone.length > 11 ? phone[11] : phone.length === 11 && '|'}
                </div>
              </>
              :
              <>
                <div className={code.length === 0 ? 'active' : ''}>
                  {code.length > 0 ? code[0] : '|'}
                </div>
                <div className={code.length === 1 ? 'active' : ''}>
                  {code.length > 1 ? code[1] : code.length === 1 && '|'}
                </div>
                <div
                  style={{marginRight: '20px'}}
                  className={code.length === 2 ? 'active' : ''}>
                  {code.length > 2 ? code[2] : code.length === 2 && '|'}
                </div>
                <div className={code.length === 3 ? 'active' : ''}>
                  {code.length > 3 ? code[3] : code.length === 3 && '|'}
                </div>
                <div className={code.length === 4 ? 'active' : ''}>
                  {code.length > 4 ? code[4] : code.length === 4 && '|'}
                </div>
                <div
                  style={{marginRight: '20px'}}
                  className={code.length === 5 ? 'active' : ''}>
                  {code.length > 5 ? code[5] : code.length === 5 && '|'}
                </div>
                <div className={code.length === 6 ? 'active' : ''}>
                  {code.length > 6 ? code[6] : code.length === 6 && '|'}
                </div>
                <div className={code.length === 7 ? 'active' : ''}>
                  {code.length > 7 ? code[7] : code.length === 7 && '|'}
                </div>
                <div className={code.length === 8 ? 'active' : ''}>
                  {code.length > 8 ? code[8] : code.length === 8 && '|'}
                </div>
              </>
            }
          </div>
          <div className="codeRestore">
            <span onClick={() => {
              setPhone('998')
              setCode('')
              setShowPhoneInput(!showPhoneInput)
            }}>
              {showPhoneInput ?
                "Ortga qaytish" :
                "PIN kodni tiklash"
              }
            </span>
          </div>
          <div className="buttonNumber">
            {(login.isLoading || resetPassword.isLoading) ?
              <Spin size={"large"} className={'loading'}/>
              :
              <>
                <div className="one">
                  <div
                    onClick={() => changeFunc('1')}>
                    1
                  </div>
                  <div
                    onClick={() => changeFunc('2')}>
                    2
                  </div>
                  <div
                    onClick={() => changeFunc('3')}>
                    3
                  </div>
                </div>
                <div className="one">
                  <div
                    onClick={() => changeFunc('4')}>
                    4
                  </div>
                  <div
                    onClick={() => changeFunc('5')}>
                    5
                  </div>
                  <div
                    onClick={() => changeFunc('6')}>
                    6
                  </div>
                </div>
                <div className="one">
                  <div
                    onClick={() => changeFunc('7')}>
                    7
                  </div>
                  <div
                    onClick={() => changeFunc('8')}>
                    8
                  </div>
                  <div
                    onClick={() => changeFunc('9')}>
                    9
                  </div>
                </div>
                <div className="one">
                  <div className="return" style={{cursor: 'unset'}}/>
                  <div
                    onClick={() => changeFunc('0')}>
                    0
                  </div>
                  <div onClick={deleteFunc} className="return">
                    <Return/>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
