import React from "react";
import {Button, Modal} from "antd";
import CloseModal from "../../assets/svg/CloseModal";
import {DownloadFilesType} from "../../utils/models/response/IStudentBallRes";
import {useDownloadStudentFileMutation} from "../../queries/mutations";

interface ViewModalInterface {
  file: string,
  open: boolean
  close: () => void
  fileName: string
  id: number
  type: DownloadFilesType
}

const ViewModal:React.FC<ViewModalInterface> = ({file,open,close,type,fileName,id}) => {
  const download = useDownloadStudentFileMutation(fileName)

  const downloadFunc = () => {
    download.mutate({
      id,
      type
    })
  }
  return (
    <Modal
      title="Fileni ko'rish"
      visible={open}
      closeIcon={<CloseModal/>}
      width={1000}
      footer={[
        <Button
          type={'primary'}
          loading={download.isLoading}
          onClick={downloadFunc}>
          Yuklab olish
        </Button>
      ]}
      centered
      onCancel={close}>
      <iframe
        title={'view file iframe'}
        height="420"
        width="950"
        src={`https://docs.google.com/gview?url=${file}&embedded=true`}/>
    </Modal>
  )
}

export default ViewModal