import React from 'react';

function Import() {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.45778 0C7.64706 0 7.80606 0.147 7.80606 0.322V2.576C7.80606 3.857 8.94176 4.907 10.3349 4.914C10.9027 4.914 11.357 4.921 11.7053 4.921C11.94 4.921 12.3261 4.914 12.6517 4.914C12.841 4.914 13 5.054 13 5.229V10.857C13 12.593 11.4706 14 9.59289 14H3.57368C1.59755 14 0 12.523 0 10.703V3.157C0 1.421 1.52184 0 3.41468 0H7.45778ZM6.22365 4.718C6.14793 4.718 6.07222 4.732 6.00408 4.76C5.93594 4.788 5.87536 4.823 5.82236 4.872L3.65696 6.888C3.43739 7.091 3.43739 7.42 3.65696 7.623C3.87653 7.826 4.23238 7.826 4.45195 7.623L5.6558 6.503V9.884C5.6558 10.171 5.90565 10.402 6.22365 10.402C6.53407 10.402 6.78393 10.171 6.78393 9.884V6.503L7.98777 7.623C8.20734 7.826 8.56319 7.826 8.78276 7.623C9.0099 7.42 9.0099 7.091 8.79033 6.888L6.61736 4.872C6.56436 4.823 6.50379 4.788 6.43564 4.76C6.3675 4.732 6.29936 4.718 6.22365 4.718ZM8.90519 0.6342C8.90519 0.3325 9.30041 0.1827 9.5268 0.4004C10.346 1.1872 11.7762 2.5627 12.5758 3.3313C12.7961 3.5434 12.6341 3.8955 12.3153 3.8962C11.693 3.8983 10.9601 3.8962 10.4323 3.8913C9.59494 3.8913 8.90519 3.2536 8.90519 2.4794V0.6342Z" fill="#364453"/>
    </svg>
  );
}

export default Import;