import React, {useEffect} from "react";
import {Button, Form, Input, Modal, Select} from "antd";
import CloseModal from "../../assets/svg/CloseModal";
import SaveButtonIcon from "../../assets/svg/SaveButtonIcon";
import InputMask from "react-input-mask";
import {User, UserRoles, UserUpdate} from "../../utils/models/User";
import {useCreateUserMutation, useUpdateUserMutation} from "../../queries/mutations";

const {Option} = Select

interface InterfaceCreateAndEditMembers {
  close: () => void
  open: boolean,
  role: UserRoles,
  title: string,
  data: User | null,
}

const CreateAndEditUser: React.FC<InterfaceCreateAndEditMembers> = ({close, open, role, title, data}) => {
  const [form] = Form.useForm<UserUpdate>()

  const createUser = useCreateUserMutation(close, form)
  const updateUser = useUpdateUserMutation(close, form)

  useEffect(() => {
    if (data) {
      if(role === 'director') {
        form.setFieldsValue({
          name: data.name,
          phone: data.phone,
          type: data.type
        })
      } else {
        form.setFieldsValue({
          name: data.name,
          phone: data.phone
        })
      }
    }
    // eslint-disable-next-line
  }, [data]);


  const onFinish = (val: UserUpdate) => {
    if (data?.id) {
      updateUser.mutate({
        ...val,
        id: data?.id
      })
    } else {
      createUser.mutate({
        ...val,
        role
      })
    }
  }


  return (
    <Modal
      visible={open}
      className={'saveModal'}
      title={title}
      onCancel={() => {
        form.resetFields()
        close()
      }}
      closeIcon={<CloseModal/>}
      footer={false}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        initialValues={{remember: true}}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className={'editInputsCont'}>
          <label style={{width: '10%', transform: 'translateY(-12px)'}}>
            Nomi:
          </label>
          {/*@ts-ignore*/}
          <Form.Item
            name="name"
            rules={[{required: true, message: 'F.I.O kiritilmagan!'}]}
          >
            <Input placeholder={'Nomi'} style={{width: '425px', marginLeft: 'auto'}}/>
          </Form.Item>
        </div>
        <div className={'editInputsCont'}>
          <label style={{width: '30%', transform: 'translateY(-12px)'}}>
            Telefon raqami
          </label>
          {/*@ts-ignore*/}
          <Form.Item
            name="phone"
            style={{width: '70%'}}
            rules={[{required: true, message: 'Telefon raqami kiritilmagan!'}]}
          >
            {/*@ts-ignore*/}
            <InputMask style={{width: '330px'}} mask="+\9\9\8 99 999 99 99" maskChar={""}>
              {(inputProps: any) => <Input {...inputProps}
                                           placeholder="+998 XX XXX XX XX"/>}
            </InputMask>
          </Form.Item>
        </div>
        {role === "director" &&
        <div className={'editInputsCont'}>
          <label style={{width: '30%', transform: 'translateY(-12px)'}}>
            Lavozimi
          </label>
          {/*@ts-ignore*/}
          <Form.Item
            name="type"
            style={{width: '70%'}}
            rules={[{required: true, message: 'Telefon raqami kiritilmagan!'}]}
          >
            <Select placeholder={'Lavozimi...'} style={{width: '330px'}}>
              <Option value={'rais'}>
                Rais
              </Option>
              <Option value={'assistant'}>
                O'rinbosari
              </Option>
            </Select>
          </Form.Item>
        </div>
        }
        <div className={'footer'}>
          <Button
            loading={createUser.isLoading || updateUser.isLoading}
            shape="round"
            type={'primary'}
            className={'saveButtonCont'}
            htmlType={'submit'}>
            <SaveButtonIcon/>
            <span>
              Saqlash
            </span>
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CreateAndEditUser