import {ILoginResponse} from "../utils/models/response/ILoginResponse";
import {LocalStorage} from "./LocalStorage";
import {TOKEN_KEY, USER_KEY} from "../utils/constants/localStorageKeys";
import {userActions} from "../store/user/user.slice";
import {rootPaths} from "../utils/routes/rootPaths";


export const AuthService = {
  login(res: ILoginResponse, dispatch: any, push: any) {
    console.log(res)
    LocalStorage.set(TOKEN_KEY, res.Authorization)
    LocalStorage.set(USER_KEY, res.user)
    dispatch(userActions.setUser(res.user))
    if (res.user.role === 'commission')
      push(rootPaths.commission)
    else
      push(rootPaths.main+'/new')
  },
  logout() {
    window.location.replace(window.location.origin)
    localStorage.clear()
  },
}
