import React, { useState } from 'react'
import './Commission.scss'
import Checked from '../../../assets/svg/Checked'
import { useCommissionNavbar } from '../../../queries/queries'
import { Spin } from 'antd'
import { Minus } from '../../../assets/svg/Minus'
import { useHistory } from 'react-router'
import { rootPaths } from '../../../utils/routes/rootPaths'
import { Redirect, useLocation } from 'react-router-dom'

function CommissionNavbar() {
  const [showStudent, setShowStudent] = useState({
    id: 0,
    show: false,
  })

  const { push } = useHistory()
  const { pathname } = useLocation()
  const idStudent: string | null = pathname.split('/')[2]

  const { data, isLoading } = useCommissionNavbar()

  const showFunc = (id: number) => {
    if (id === showStudent.id) {
      setShowStudent({
        id,
        show: !showStudent.show,
      })
    } else {
      setShowStudent({
        id,
        show: true,
      })
    }
  }

  const selectStudentFunc = (id: number) => {
    push(`${rootPaths.commission}/${id}`)
  }

  if (isLoading)
    return (
      <div className={'commissionNavbar'}>
        <Spin className={'commissionNavbar_spin'} />
      </div>
    )

  if (idStudent && !parseInt(idStudent)) return <Redirect to={rootPaths.fof} />

  return (
    <>
      <div className="commissionNavbar">
        <ul className="commissionNavbar_ul">
          {data?.map((item,i) => (
            <li
              className={
                item.id === showStudent.id && showStudent.show ? 'active' : ''
              }
              onClick={(e) => {
                e.stopPropagation()
                showFunc(item.id)
              }}
              key={item.id}
            >
              <p title={item.name}>
                <span style={{
                  marginRight: "5px"
                }}>
                  {i+1}.
                </span>
                {item.name.length > 65 ? item.name.slice(0,65) + " ..." : item.name}
                {item.student.length > 0 && (
                  <span
                    style={{
                      marginLeft: '12px',
                    }}
                  >
                    &#x25BC;
                  </span>
                )}
              </p>
              <ul
                onClick={(e) => {
                  e.stopPropagation()
                }}
                className="dropdownMenu"
              >
                {item.student.map((st, index) => (
                  <li
                    onClick={() => {
                      selectStudentFunc(st.id)
                    }}
                    key={st.id}
                  >
                    <p
                      title={st.name}
                      className={st.id === parseInt(idStudent) ? 'active' : ''}>
                      {st.status === 'done' ?
                        <span className={'statusDone'}>
                          <Checked />
                        </span> :
                        <Minus />}
                      {index + 1}. {st.name.length > 49 ? st.name.slice(0,49) + "..." : st.name}
                    </p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default CommissionNavbar
