import React from "react";

const LogOut = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.30906 -3.79893e-07C9.16593 -2.98727e-07 10.6901 1.50233 10.7757 3.38493L10.7795 3.552L10.7795 7.384L6.07913 7.384C5.74227 7.384 5.47592 7.656 5.47592 8C5.47592 8.308 5.69973 8.56894 5.9965 8.6103L6.07913 8.616L10.7795 8.616L10.7795 12.44C10.7795 14.344 9.30835 15.9083 7.45765 15.9961L7.29339 16L3.47827 16C1.61379 16 0.0893758 14.4977 0.0037886 12.6151L-3.50363e-07 12.448L3.81435e-08 3.56C1.2171e-07 1.64823 1.47853 0.0912777 3.32248 0.00386986L3.4861 -5.47e-07L7.30906 -3.79893e-07ZM12.7354 5.24016C12.9453 5.01471 13.2762 4.99421 13.5082 5.17266L13.5746 5.23216L15.8229 7.56016C15.9384 7.68016 16 7.83216 16 8.00016C16 8.12816 15.9606 8.25616 15.8857 8.35958L15.8229 8.43216L13.5746 10.7602C13.4591 10.8802 13.3051 10.9442 13.1588 10.9442C13.0048 10.9442 12.8508 10.8802 12.7354 10.7602C12.5254 10.542 12.5063 10.1982 12.6781 9.95715L12.7354 9.88816L13.9673 8.61616L10.7796 8.61616L10.7796 7.38416L13.9673 7.38416L12.7354 6.11216C12.5044 5.87216 12.5044 5.48016 12.7354 5.24016Z" fill="#3F4B57"/>
    </svg>
  )
}

export default LogOut