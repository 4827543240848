import {Button, Result} from 'antd'
import React from 'react'
import {useHistory} from 'react-router'
import {mainPaths} from '../../utils/routes/mainRoutes'
import {rootPaths} from '../../utils/routes/rootPaths'
import {useAppSelector} from "../../utils/helpers/customizedHooks";

interface FourOFourProps {
}

export const FourOFour: React.FC<FourOFourProps> = () => {
  const {push} = useHistory()

  const role = useAppSelector((state) => state.user.user?.role)

  const goToHomePage = () => {
    if (role === 'commission')
      push(rootPaths.commission)
    else
      push(rootPaths.main + mainPaths.home)
  }

  return (
    <Result
      status='404'
      title='404'
      subTitle='Bu sahivaga kirish huquqi sizda mavjud emas.'
      extra={
        <Button onClick={goToHomePage} type='primary'>
          Bosh sahifaga qaytish
        </Button>
      }
    />
  )
}
