import React from "react";

const PlusBlue = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99992 13.6667C10.6818 13.6667 13.6666 10.6819 13.6666 7.00004C13.6666 3.31814 10.6818 0.333374 6.99992 0.333374C3.31802 0.333374 0.333252 3.31814 0.333252 7.00004C0.333252 10.6819 3.31802 13.6667 6.99992 13.6667ZM7.49992 5.00004C7.49992 4.7239 7.27606 4.50004 6.99992 4.50004C6.72378 4.50004 6.49992 4.7239 6.49992 5.00004V6.50004H4.99992C4.72378 6.50004 4.49992 6.7239 4.49992 7.00004C4.49992 7.27618 4.72378 7.50004 4.99992 7.50004H6.49992V9.00004C6.49992 9.27618 6.72378 9.50004 6.99992 9.50004C7.27606 9.50004 7.49992 9.27618 7.49992 9.00004V7.50004H8.99992C9.27606 7.50004 9.49992 7.27618 9.49992 7.00004C9.49992 6.7239 9.27606 6.50004 8.99992 6.50004H7.49992V5.00004Z" fill="#649BD3"/>
    </svg>
  )
}

export default PlusBlue