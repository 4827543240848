import React from "react";
import {Col, Row} from "antd";
import Pencil from "../../../assets/svg/Pencil";
// import Garbage from "../../../assets/svg/Garbage";
import {BossType, User} from "../../../utils/models/User";
import {useAppSelector} from "../../../utils/helpers/customizedHooks";
import Avatar from "../../../assets/svg/Avatar";

interface InterfaceOneLeader {
  data: User,
  editData: (data: User) => void,
}

const OneLeader: React.FC<InterfaceOneLeader> = ({data, editData}) => {
  const role = useAppSelector((state) => state.user.user?.role)

  const returnBossType = (type: BossType) => {
    if(type === "rais")
      return "Rais"
    return "O'rinbosari"
  }

  return (
    <Row gutter={[8, 8]} className={'tableBody'}>
      <Col span={role === 'director' ? 14 : 10} className={'item nameUser'}>
        <div className="avatarIcon">
          <Avatar />
        </div>
        {data.name}
      </Col>
      <Col span={4} className={'item'}>
        {returnBossType(data.type || 'rais')}
      </Col>
      <Col span={6} className={'item'}>
        {data.phone}
      </Col>
      {role !== 'director' &&
      <Col span={4} className={'item'}>
        <span onClick={() => editData(data)}>
          <Pencil/>
        </span>
        {/*<Garbage/>*/}
      </Col>
      }
    </Row>
  )
}

export default OneLeader