import React from 'react'
import { Input } from 'antd'
import {
  ICriteria,
  ICriteriaForUse,
} from '../../utils/models/response/IStudentBallRes'

interface IOneCard {
  criteria: ICriteria
  ballArr: ICriteriaForUse[]
  index: number
  setBall: React.Dispatch<React.SetStateAction<ICriteriaForUse[]>>
  status: 'done' | 'doing'
}

const OneCard: React.FC<IOneCard> = ({
  criteria,
  ballArr,
  setBall,
  index,
  status,
}) => {
  const handleChangeInput = (e: any) => {
    const val = parseFloat(e.target.value)
    const newArr = [...ballArr]
    if (val) {
      if (val >= 0 && val <= criteria.max_ball) {
        newArr[index].ball = Math.floor(val*100)/100
      } else if (val > criteria.max_ball) {
        newArr[index].ball = criteria.max_ball
      } else if (val < 0) {
        if (-val > criteria.max_ball) newArr[index].ball = criteria.max_ball
        else newArr[index].ball = -Math.floor(val*100)/100
      }
    } else {
      newArr[index].ball = 0
    }
    setBall(newArr)
  }

  return (
    <div className={'card'}>
      <div className={'card_title'}>{criteria.description}</div>

      <div className="card_body">
        <div className={'card_body_max'}>{criteria.max_ball} Ball</div>
        <Input
          disabled={status === 'done'}
          type={'number'}
          className={'card_body_input'}
          min={0}
          onChange={handleChangeInput}
          value={ballArr[index]?.ball !== 0 ? ballArr[index]?.ball : undefined}
          placeholder={'0'}
        />
      </div>
    </div>
  )
}

export default OneCard
