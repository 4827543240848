import React from "react";

const Checked = () => {
  return (
    <svg className={'checked'} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M9.00008 17.3334C13.6025 17.3334 17.3334 13.6024 17.3334 9.00002C17.3334 4.39765 13.6025 0.666687 9.00008 0.666687C4.39771 0.666687 0.666748 4.39765 0.666748 9.00002C0.666748 13.6024 4.39771 17.3334 9.00008 17.3334ZM13.6324 6.91691C13.8626 6.65974 13.8408 6.26462 13.5836 6.03437C13.3265 5.80413 12.9313 5.82596 12.7011 6.08313L9.89346 9.21911C9.32454 9.85456 8.94141 10.2802 8.61385 10.5556C8.3018 10.8178 8.11852 10.875 7.95842 10.875C7.79831 10.875 7.61503 10.8178 7.30298 10.5556C6.97542 10.2802 6.59229 9.85456 6.02337 9.21911L5.29906 8.4101C5.06882 8.15293 4.67369 8.1311 4.41652 8.36134C4.15935 8.59159 4.13753 8.98671 4.36777 9.24388L5.12302 10.0875C5.6529 10.6793 6.09487 11.173 6.49871 11.5125C6.92591 11.8715 7.38669 12.125 7.95842 12.125C8.53014 12.125 8.99092 11.8715 9.41812 11.5125C9.82196 11.173 10.2639 10.6793 10.7938 10.0875L13.6324 6.91691Z"
            fill="white"/>
    </svg>
  )
}

export default Checked