import React from 'react';
import GraduationCap from "../../assets/svg/GraduationCap";

function NotSelect() {
  return (
    <div className={'empty'}>
      <div>
        <GraduationCap/>
      </div>
      <div className={'empty_title'}>
        Tinglovchi tanlanmagan!
      </div>
    </div>
  );
}

export default NotSelect;