import React, {useState} from 'react'
import {Col, Popconfirm, Row, Spin} from 'antd'
import Garbage from '../../../assets/svg/Garbage'
import Mark from '../../../assets/svg/Mark'
import Eye from '../../../assets/svg/Eye'
import {IStudent} from '../../../utils/models/IStudent'
import Document from '../../../assets/svg/Document'
import CreateAndEditStudentModal from './CreateAndEditStudentModal'
import {useDeleteStudentMutation, useDownloadStudentFileMutation} from '../../../queries/mutations'
import ModalEvaluate from './ModalEvaluate'

interface InterfaceOneStudent {
  index: number
  student: IStudent
  id: number,
  date: string
}

const OneStudent: React.FC<InterfaceOneStudent> = ({student, index, id,date}) => {
  const [openEditModal, setOpenEditModal] = useState(false)
  const [infoModalOpen, setInfoModalOpen] = useState(false)

  const deleteStudent = useDeleteStudentMutation(id)

  const closeFunc = () => {
    setOpenEditModal(false)
  }

  const download = useDownloadStudentFileMutation(student.file_name || '')
  const downloadPpt = useDownloadStudentFileMutation(student.ppt_name || '')

  const downloadFunc = () => {
    download.mutate({
      id: student.id,
      type: 'doc'
    })
  }

  const downloadPptFunc = () => {
    downloadPpt.mutate({
      id: student.id,
      type: 'ppt'
    })
  }

  const deleteFunc = () => {
    deleteStudent.mutate(student.id)
  }

  const titleFunc = () => {
    switch (student.type) {
      case 'red': return "Imtihondan yiqilgan"
      case 'white': return "Komissiya a'zolari baholamagan"
      case 'black': return "Imtihonga kiritilmagan"
      case 'green': return "Imtihonsiz tafsiya etilgan"
    }
  }

  return (
    <>
      <Row gutter={[8, 8]} className={'tableBody'}>
        <Col span={1} className={'item itemName'}>
          <div title={titleFunc()} className={student.type} />
        </Col>
        <Col span={7} className={'item'}>
          {index + 1}. {student.name}
        </Col>
        <Col span={4} className={'item'}>
          {student?.ball} ball
        </Col>
        <Col span={5} className={'item'}>
          {download.isLoading ? (
            <Spin/>
          ) : (
            student.file_name !== '0' && (
              <>
                <Document/>
                <span title={student.file_name} onClick={downloadFunc} className={'link'}>
                  Bitiruv malakaviy ish
                </span>
              </>
            )
          )}
        </Col>
        <Col span={5} className={'item'}>
          {downloadPpt.isLoading ? (
            <Spin/>
          ) : (
            student.ppt_name !== '0' && (
              <>
                <Document/>
                <span title={student.ppt_name} onClick={downloadPptFunc} className={'link'}>
                  Taqdimot
                </span>
              </>
            )
          )}
        </Col>
        <Col span={2} className={'item'}>
          <span onClick={() => setOpenEditModal(true)}>
            <Mark/>
          </span>
          <span onClick={() => setInfoModalOpen(true)}>
            <Eye/>
          </span>
          {deleteStudent.isLoading ?
            <Spin/> :
            <Popconfirm
              placement="topRight"
              title={"Tinglovchi o'chirishni tasdiqlash!"}
              onConfirm={deleteFunc}
              okText="Tasdiqlash"
              cancelText="Ortga qaytish"
            >
              <span>
                <Garbage/>
              </span>
            </Popconfirm>
          }
        </Col>
      </Row>
      <CreateAndEditStudentModal
        date={date}
        id={id}
        title={"Ma'lumotlarni yangilash"}
        data={student}
        open={openEditModal}
        closeFunc={closeFunc}
      />
      <ModalEvaluate
        id={student.id}
        name={student.name}
        close={setInfoModalOpen}
        isModalVisible={infoModalOpen}
      />
    </>
  )
}

export default OneStudent
