import React, {useState} from "react";
import {Button} from "antd";
import DownloadWhite from "../../assets/svg/DownloadWhite";
import {LoadingOutlined} from "@ant-design/icons";
import ViewModal from "../../components/modal/ViewModal";
import PptIcon from "../../assets/svg/PptIcon";
import {DownloadFilesType} from "../../utils/models/response/IStudentBallRes";

interface IHeaderCommission {
  name: string
  isFetching: boolean
  file_name: string
  file: string
  id: number
  ppt: string
  ppt_name: string
}

const HeaderCommission: React.FC<IHeaderCommission> = ({name, file_name, isFetching, file, id, ppt, ppt_name}) => {
  const [openViewModal, setOpenViewModal] = useState<{
    isOpen: boolean
    type: DownloadFilesType
    name: string
    url: string
  }>({
    isOpen: false,
    type: "doc",
    name: '',
    url: ''
  });


  const closeFunc = () => {
    setOpenViewModal({
      isOpen: false,
      type: 'doc',
      name: '',
      url: ''
    })
  }

  console.log(ppt_name,file_name)

  return (
    <div className={'header'}>
      <div className={'header_left'}>
        <span className={'header_left_label'}>
          Tinglovchi:
        </span>
        <span className={'header_left_name'}>
          {name}
          {isFetching && <LoadingOutlined/>}
        </span>
      </div>
      <div className={'header_right'}>
        <span>
          Ijodiy ish fayli:
        </span>
        {
          file_name !== "0" &&
          <Button
            title={'Tinglovchi ishini yuklab olish'}
            onClick={() => {
              setOpenViewModal({
                isOpen: true,
                type: "doc",
                name: file_name,
                url: file
              })
            }}
            className={'header_right_button'}
            type={'primary'}
            shape={'round'}>
            <DownloadWhite/>
            <span>
            Malakaviy ish
          </span>
          </Button>
        }
        {ppt_name !== "0" &&
        <Button
          title={"Tinglovchi ishini online ko'rish"}
          onClick={() => {
            setOpenViewModal({
              isOpen: true,
              type: 'ppt',
              name: ppt_name,
              url: ppt
            })
          }}
          className={'header_right_button'}
          type={'primary'}
          shape={'round'}>
          <PptIcon/>
          <span>
            Taqdimot
          </span>
        </Button>
        }
      </div>
      <ViewModal
        file={openViewModal.url}
        close={closeFunc}
        id={id}
        type={openViewModal.type}
        fileName={openViewModal.name}
        open={openViewModal.isOpen}/>
    </div>
  )
}

export default HeaderCommission