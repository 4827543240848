import React, { useEffect, useState } from 'react'
import OneCard from './OneCard'
import LastCard from './LastCard'
import { Button, Popconfirm } from 'antd'
import Checked from '../../assets/svg/Checked'
import {
  ICriteria,
  ICriteriaForUse,
} from '../../utils/models/response/IStudentBallRes'
import { useEvaluationMutation } from '../../queries/mutations'

interface EvaluationContInterface {
  id: number
  criteria: ICriteria[]
  status: 'doing' | 'done'
}

const EvaluationCont: React.FC<EvaluationContInterface> = ({
  id,
  criteria,
  status,
}) => {
  const [ballArr, setBallArr] = useState<ICriteriaForUse[]>([])

  const evaluate = useEvaluationMutation(id)

  useEffect(() => {
    const newArr: ICriteriaForUse[] = []
    criteria.forEach((item) => {
      newArr.push({
        ball: item.ball,
        criteria_id: item.id,
      })
    })
    setBallArr(newArr)
  }, [criteria])

  const evaluateFunc = () => {
    evaluate.mutate({
      student_id: id,
      criteria_ids: ballArr,
    })
  }

  return (
    <div className={'evaluation'}>
      <div className={'evaluation_title'}>
        Attestatsiya komissiyasining baholar natijasi
      </div>
      <div className={'evaluation_main'}>
        {criteria.map((one, index) => (
          <OneCard
            status={status}
            index={index}
            ballArr={ballArr}
            setBall={setBallArr}
            key={index}
            criteria={one}
          />
        ))}
        <LastCard ballArr={ballArr} />
      </div>
      <div className={'evaluation_footer'}>
        {status !== 'done' && (
          <Popconfirm
            placement="topRight"
            title={
              <>
                <div>Baho qo'yilmagan me'zonlar 0 ball bilan baholanadi!</div>
                <div>
                  Tasdiqlashdan so'ng bahoni qayta o'zgartirish imkoni yo'qoladi!
                </div>
              </>
            }
            onConfirm={evaluateFunc}
            okText="Tasdiqlash"
            cancelText="Bekor qilish"
          >
            <Button
              loading={evaluate.isLoading}
              className={'evaluation_footer_button'}
              type={'primary'}
              shape={'round'}
            >
              <Checked />
              <span>Tasdiqlash</span>
            </Button>
          </Popconfirm>
        )}
      </div>
    </div>
  )
}

export default EvaluationCont
