import React from "react";

const Settings = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.50004 8.00001C5.50004 7.17158 6.17161 6.50001 7.00004 6.50001C7.82847 6.50001 8.50004 7.17158 8.50004 8.00001C8.50004 8.82844 7.82847 9.50001 7.00004 9.50001C6.17161 9.50001 5.50004 8.82844 5.50004 8.00001Z" fill="#3F4B57"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.72353 2.15487C5.37714 -0.135387 8.62287 -0.135388 9.27648 2.15487C9.47899 2.86447 10.208 3.28538 10.9238 3.10595C13.234 2.52687 14.8569 5.33776 13.2003 7.04893C12.687 7.57911 12.687 8.42092 13.2003 8.9511C14.8569 10.6623 13.234 13.4732 10.9238 12.8941C10.208 12.7147 9.47899 13.1356 9.27648 13.8452C8.62287 16.1354 5.37714 16.1354 4.72353 13.8452C4.52102 13.1356 3.79199 12.7147 3.0762 12.8941C0.765975 13.4732 -0.856893 10.6623 0.79972 8.9511C1.313 8.42092 1.313 7.57911 0.799721 7.04893C-0.856893 5.33776 0.765975 2.52687 3.0762 3.10595C3.79199 3.28538 4.52102 2.86447 4.72353 2.15487ZM7.00004 5.50001C5.61933 5.50001 4.50004 6.6193 4.50004 8.00001C4.50004 9.38072 5.61933 10.5 7.00004 10.5C8.38075 10.5 9.50004 9.38072 9.50004 8.00001C9.50004 6.6193 8.38075 5.50001 7.00004 5.50001Z" fill="#3F4B57"/>
    </svg>
  )
}

export default Settings