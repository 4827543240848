import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { Polygonn } from './Polygonn'

const NavbarSettings = () => {
  const location = useLocation()
  const history = useHistory()
  const clickFaculties = () => {
    history.push('/settings/faculties')
  }
  const clickMembers = () => {
    history.push('/settings/members')
  }
  const clickLeader = () => {
    history.push('/settings/leader')
  }

  return (
    <div className={'body_sections_navbar'}>
      <p className="settingsTitle">Sozlama</p>
      <p
        className={`${
          location.pathname === '/settings/members' ? 'active' : 'settingsMenu'
        }`}
        onClick={clickMembers}
      >
        <span
          style={
            location.pathname === '/settings/members'
              ? {
                  display: 'inline-block',
                  position: 'absolute',
                  transform: 'translate(-20px ,1px)',
                }
              : { display: 'none' }
          }
        >
          <Polygonn />
        </span>
        Komissiya a'zolari
      </p>
      <p
        className={`${
          location.pathname === '/settings/faculties' ? 'active' : 'settingsMenu'
        }`}
        onClick={clickFaculties}
      >
        <span
          style={
            location.pathname === '/settings/faculties'
              ? {
                  display: 'inline-block',
                  position: 'absolute',
                  transform: 'translate(-20px ,1px)',
                }
              : { display: 'none' }
          }
        >
          <Polygonn />
        </span>
        Yo'nalishlar
      </p>
      <p
        className={`${
          location.pathname === '/settings/leader' ? 'active' : 'settingsMenu'
        }`}
        onClick={clickLeader}
      >
        <span
          style={
            location.pathname === '/settings/leader'
              ? {
                  display: 'inline-block',
                  position: 'absolute',
                  transform: 'translate(-20px ,1px)',
                }
              : { display: 'none' }
          }
        >
          <Polygonn />
        </span>
        Boshqaruv a'zolari
      </p>
    </div>
  )
}

export default NavbarSettings
