import React, {useState} from 'react'
import {PlusCircle} from '../../assets/svg/PlusCircle'
import {Minus} from '../../assets/svg/Minus'
import {useGroupList} from '../../queries/queries'
import {Spin} from 'antd'
import Checked from '../../assets/svg/Checked'
import Calendar from '../../assets/svg/Calendar'
import {useLocation} from 'react-router-dom'
import {useHistory} from 'react-router'
import {rootPaths} from '../../utils/routes/rootPaths'
import CreateNewDirection from '../../pages/main/home/CreateNewDirection'
import ChooseDirectionTimeModal from "../modal/ChooseDirectionTimeModal";

const HomeNavbar = () => {
  const {pathname} = useLocation()
  const {push} = useHistory()

  const [openEditModal, setOpenEditModal] = useState(false)
  const [dataModalVisible, setDataModalVisible] = useState(false)

  const {data, isLoading, isFetching} = useGroupList(pathname.split('/')[2])

  const goToStudentList = (id: number) => {
    push(`${rootPaths.main}/${pathname.split('/')[2]}/${id}`)
  }

  const closeFunc = () => {
    setOpenEditModal(false)
  }
  const dateShowModal = () => {
    setDataModalVisible(true)
  }

  const handleCancel = () => {
    setDataModalVisible(false)
  }

  const backGroupDate = () => {
    if(pathname.split('/')[2] !== 'new')
      return pathname.split('/')[2]
    if(data && data[0] && data[0].date)
      return data[0].date + '-' + data[0].date_end
    else
      return 'guruhlarni tanlash'
  }

  return (
    <div className="navbarHome">
      <div className="navbarHeader">
        <div className="dataInterval" onClick={dateShowModal}>
          <span>
            <Calendar/>
          </span>
          {backGroupDate()}
        </div>
        <div
          className="createFaculties"
          onClick={() => {
            setOpenEditModal(true)
          }}
        >
          <span className="icon">
            <PlusCircle/>
          </span>
          <span className="title">Yangi qo'shish</span>
        </div>
      </div>
      {isLoading ? (
        <Spin className={'spinner'}/>
      ) : (
        <>
          {isFetching && <Spin className={'spinner'}/>}
          <ul>
            {data?.map((group, index) => (
              <li
                className={
                  parseInt(pathname.split('/')[3]) === group.id ? 'active' : ''
                }
                onClick={() => goToStudentList(group.id)}
              >
                <p>
                <span
                  style={{
                    marginRight: '5px',
                  }}
                >
                  {index + 1}
                </span>
                  <span className="icon">
                  {group.student_count === 0 ? (
                    <span className={group.status}>
                       <Minus/>
                    </span>
                  ) : (
                    <span className={group.status}>
                      <Checked/>
                    </span>
                  )}
                </span>
                  <span
                    title={
                      group.date + '-' + group.date_end + ' ' + group.direction_name
                    }
                    className="title"
                  >
                    {group.direction_name.length > 55
                      ? group.direction_name.slice(0, 55) + ' ...'
                      : group.direction_name}
                </span>
                </p>
              </li>
            ))}
          </ul>
        </>
      )}
      <ChooseDirectionTimeModal open={dataModalVisible} close={handleCancel}/>
      <CreateNewDirection open={openEditModal} close={closeFunc}/>
    </div>
  )
}

export default HomeNavbar
