import React, {useEffect} from "react";
import {Button, Form, Input, Modal} from "antd";
import CloseModal from "../../../assets/svg/CloseModal";
import SaveButtonIcon from "../../../assets/svg/SaveButtonIcon";
import {useCreateFacultyMutation, useUpdateFacultyMutation} from "../../../queries/mutations";
import {IFaculty} from "../../../utils/models/IFaculty";


interface InterfaceCreateAndEditMembers {
  close: () => void
  open: boolean,
  data: IFaculty | null
}

const CreateAndEditFacultiesModal: React.FC<InterfaceCreateAndEditMembers> = ({close, open, data}) => {
  const [form] = Form.useForm()

  const createFaculty = useCreateFacultyMutation()
  const updateFaculty = useUpdateFacultyMutation()

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name
      })
    }
    // eslint-disable-next-line
  }, [data]);


  const onFinish = async (val: any) => {
    if (data?.id)
      await updateFaculty.mutateAsync({
        name: val.name,
        id: data.id
      })
    else
      await createFaculty.mutateAsync(val.name)
    form.resetFields()
    close()
  }


  return (
    <Modal
      visible={open}
      className={'saveModal'}
      title={"Yo'nalish yaratish"}
      onCancel={close}
      closeIcon={<CloseModal/>}
      footer={false}
    >
      <Form
        form={form}
        name="addFaculty"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        onFinish={onFinish}
      >
        <div className={'editInputsCont'}>
          <label style={{width: '10%', transform: 'translateY(-12px)'}}>
            Nomi:
          </label>
          {/*@ts-ignore*/}
          <Form.Item
            name="name"
            rules={[{required: true, message: 'F.I.O kiritilmagan!'}]}
          >
            <Input style={{width: '425px', marginLeft: 'auto'}}/>
          </Form.Item>
        </div>
        <div className={'footer'}>
          <Button
            loading={createFaculty.isLoading || updateFaculty.isLoading}
            shape="round"
            type={'primary'}
            className={'saveButtonCont'}
            htmlType={'submit'}>
            <>
              <SaveButtonIcon/>
              <span>
                Saqlash
              </span>
            </>
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CreateAndEditFacultiesModal